import axios, { AxiosResponse } from "axios";
interface UserAuth {
  id: string;
  name: string;
  token: string;
  email: string
}

export default new (class AuthService {
  private userAuth?: UserAuth;
  public getUserAuth(): UserAuth | undefined {
    return this.userAuth;
  }
  public onAuthChange?: (
    before: UserAuth | undefined,
    now: UserAuth | undefined,
    verb?: "SIGNUP"
  ) => void;

  public async revalidateLogin() {
    

    if (!this.userAuth && !localStorage.getItem("user")) {
      return;
    }

    if (localStorage.getItem("user")) {
      

      const user = JSON.parse(localStorage.getItem("user")!) as UserAuth;
      this.userAuth = user;
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${
      this.userAuth!.token
    }`;
    const response = await axios.get<UserAuth>("/User/me");
    

    if (response.status >= 200 && response.status < 300) {
      if (this.onAuthChange) {
        this.onAuthChange(this.getUserAuth(), response.data);
      }
    } else {
      this.logout();
      if (this.onAuthChange) {
        this.onAuthChange(this.getUserAuth(), undefined);
      }
    }
  }

  

  public async googleLogin(token: string, acceptedCGU: boolean = false, name?: string, addInNewsLetterList: boolean = false) {
    type error = {
        StringCode: string;
        StatusCode: number;
        Message: string;
    }
    const response = await axios.post<UserAuth & error>("/User/authenticate/google", {
      token,
      acceptedCGU,
      username: name,
      addInNewsLetterList
    });
    if (response.status >= 200 && response.status < 300) {
      localStorage.setItem("user", JSON.stringify(response.data));

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.token}`;

      

      this.userAuth = response.data;
      if (this.onAuthChange) {
        this.onAuthChange(this.getUserAuth(), response.data);
      }
    }
    return response;
  }

  public async authenticate(
    model: {
      email: string;
      password: string;
    },
    then?: (valid: boolean) => void
  ) {
    const response = await axios.post("/User/authenticate", model);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.token}`;
    this.userAuth = response.data;
    if (then) {
      localStorage.setItem("user", JSON.stringify(response.data));
      then(response.status >= 200 && response.status < 300);
    }
  }

  public async register(
    model: {
      username: string;
      email: string;
      password: string;
      addInNewsLetterList: boolean;
    },
    then?: (response: AxiosResponse) => void
  ) {
    const response = await axios.post("/User/register", model);
      if (then) {
        then(response);
      
    }

    return response;
  }

  public logout() {
    this.userAuth = undefined;
    localStorage.removeItem("user");
    if (this.onAuthChange) {
      this.onAuthChange(undefined, undefined);
    }
  }

  public async forgotPassword(email: string) {
    const response = await axios.post("/User/password/reset/" + email);
    if (response.status >= 200 && response.status < 300) {
      return true;
    }
    return false;
  }

  public async resetPassword(newPassword: string, code: string) {
    const response = await axios.put("/User/password/reset", {
      key: code,
      password: newPassword,
    });
    if (response.status >= 200 && response.status < 300) {
      return true;
    }
    return false;
  }
})();
