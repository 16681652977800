import { useState } from "react";
import { BackToHomePage } from "../components/BackToHomePage";
import ContactService, { ContactFormModel } from "../services/ContactService";
import { greenButton, neutralInput } from "../services/TailwindClassService";

export function ContactForm() {
  // Render contact form with subject, username, message
  const [model, setModel] = useState<ContactFormModel>({
    subject: "",
    userId: "",
    message: "",
    email: "",
  });
  const [errors, setErrors] = useState<string[]>([]);
  const [showSucces, setShowSucces] = useState(false);

  const onSubmit = async () => {
    // If any field is empty, show an error
    if (!model.subject || !model.userId || !model.message || !model.email) {
      setErrors([...errors, "Veuillez remplir tous les champs"]);
      return;
    }
    // Send contact form
    await ContactService.postMessage(model);
    setShowSucces(true);
  };

  return (
    <div>
      <BackToHomePage/>
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-3xl">Contactez-nous</h1>
        <p className="text-lg">
          Vous avez une question, une suggestion ou un problème ? N'hésitez pas
          à nous contacter.
        </p>
        {/* If show success, show messgae */}
        {(showSucces && (
          <div className="text-center">
            <p className="text-lg">Votre message a bien été envoyé.</p>
          </div>
        )) || (
          <div className="">
            <input
              className={neutralInput + " mt-6"}
              type="text"
              placeholder="Sujet"
              value={model.subject}
              onChange={(e) => {
                setModel({
                  ...model,
                  subject: e.currentTarget.value,
                });
              }}
            />
            <input
              className={neutralInput + " mt-6"}
              type="text"
              placeholder="Votre nom"
              value={model.userId}
              onChange={(e) => {
                setModel({
                  ...model,
                  userId: e.currentTarget.value,
                });
              }}
            />
            <input
              className={neutralInput + " mt-6"}
              type="text"
              placeholder="Votre email"
              value={model.email}
              onChange={(e) => {
                setModel({
                  ...model,
                  email: e.currentTarget.value,
                });
              }}
            />
            <textarea
              className={neutralInput + " mt-6"}
              placeholder="Votre message"
              value={model.message}
              onChange={(e) => {
                setModel({
                  ...model,
                  message: e.currentTarget.value,
                });
              }}
            />
          </div>
        )}
        {/* Errors */}
        {errors.length > 0 && (
          <div className="mt-6">
            {errors.map((error, index) => (
              <p key={index} className="text-red-500">
                {error}
              </p>
            ))}
          </div>
        )}

        {!showSucces && (
          <button
            className={greenButton + " m-4"}
            type="button"
            onClick={onSubmit}
          >
            Envoyer
          </button>
        )}
      </div>
    </div>
  );
}
