import { redButton } from "../services/TailwindClassService";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import LoaderService from "../services/LoaderService";
import FriendService from "../services/FriendService";

interface FriendListItemProp {
  friend: {
    type: "friend" | "friendRequest";
    id: string;
    name: string;
  };
  onAction?: () => void;
  onClick?: () => void;
}

export function FriendListItem({
  friend,
  onAction,
  onClick,
}: FriendListItemProp) {
  const { t } = useTranslation();
  let [acceptedModal, showAcceptedModal] = useState(false);
  let [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  // Function to accept a friend request

  const acceptFriendRequest = async () => {
    showAcceptedModal(true);
    LoaderService.onLoad!(true);
    await FriendService.acceptFriendRequest(friend.id);
    if (onAction) {
      onAction();
    }
    LoaderService.onLoad!(false);
  };

  const removeFriend = async () => {
    setShowConfirmDeleteModal(false);
    LoaderService.onLoad!(true);
    await FriendService.removeFriend(friend.id);
    if (onAction) {
      onAction();
    }
    LoaderService.onLoad!(false);
  };

  return (
    <>
      <Modal
        show={showConfirmDeleteModal}
        onHide={() => setShowConfirmDeleteModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Avant de continuer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Confirmez-vous vouloir supprimer {friend.name} de votre liste d'amis
            ?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={redButton}
            onClick={() => setShowConfirmDeleteModal(false)}
          >
            Non
          </button>
          <button className={redButton} onClick={removeFriend}>
            Oui
          </button>
        </Modal.Footer>
      </Modal>
      {friend.type === "friendRequest" ? (
        <Modal
          show={acceptedModal}
          onHide={() => {
            showAcceptedModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Invitation de {friend.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t("Vous êtes désormais amis avec")} {friend.name} !
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => {
                showAcceptedModal(false);
              }}
            >
              Fermer
            </button>
          </Modal.Footer>
        </Modal>
      ) : undefined}
      {friend.type === "friendRequest" ? (
        <div
          className={
            "border-b-2 border-teal-700 h-20 bg-gray-100 hover:bg-gray-200 cursor-pointer p-2 m-auto flex justify-between"
          }
        >
          {/* If it is a friend request, show accept button */}
          <div className={"align-middle inline-block"}>
            <span className="block text-left text-teal-800">Invitation</span>
            <h2 className={"text-xl pt-1 "}>{friend.name}</h2>
          </div>
          <button
            className={redButton}
            onClick={() => {
              acceptFriendRequest();
            }}
          >
            Accepter
          </button>
        </div>
      ) : (
        <div
          className={
            "border-b-2 border-teal-700 h-20 bg-gray-100 hover:bg-gray-200 cursor-pointer p-2 m-auto flex justify-between"
          }
        >
          {/* If it is a friend request, show accept button */}
          <div className={"align-middle inline-block"}>
            <div
              className="text-left"
              onClick={(e) => {
                e.preventDefault();
                if (onClick) {
                  onClick();
                }
              }}
            >
              <h2 className={"text-xxl pt-1 "}>{friend.name}</h2>
              <p className="text-sm">Voir le profil</p>
            </div>
          </div>
          <button
            className={redButton}
            onClick={() => {
              setShowConfirmDeleteModal(true);
            }}
          >
            Supprimer
          </button>
        </div>
      )}
    </>
  );
}
