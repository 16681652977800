import { useState } from "react";
import { Form } from "react-bootstrap";
import { PublishRestaurantModel } from "../../services/RestaurantService";
import { greenButton, redButton } from "../../services/TailwindClassService";
interface IRestaurantIllustrationProps {
  restaurant: PublishRestaurantModel;
  onSubmit: (files: File[]) => void;
}
export function RestaurantIllustration({
  onSubmit,
}: IRestaurantIllustrationProps) {
  
  const [files, setFiles] = useState<File[]>([]);
  const onClickPublish = () => {
    onSubmit(files);
  };
  const onClickDelete = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
  };
  return (
    <div className="h-[100%]">
      <h2>Photos</h2>
      <p>Renseignez des photos du restaurant si vous en avez...</p>
      {/* Multi file input */}
      <div className="flex flex-col items-center">
        <label htmlFor="file" className={redButton + " cursor-pointer"}>
          Sélectionnez les photos ({files.length})
        </label>
        <input
          type="file"
          placeholder="Sélectionnez les photos"
          name="file"
          id="file"
          className="text-centers m-auto hidden"
          multiple
          onChange={(e) => {
            const files = e.target.files;
            if (files) {
              setFiles(Array.from(files));
            }
          }}
          max={4}
        />
        {/* Show files as images */}
        <div className="relative flex flex-col h-[100%]">
          <div className="flex flex-wrap justify-around items-center h-[90%]">
            {files.map((file, index) => (
              <div className="relative mt-4 bg-red-700 p-4 ml-2">
                <img
                  key={file.name}
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  className="h-[200px]"
                />
                {/* Cross icon on the top right */}
                <div className="absolute top-4 right-4 cursor-pointer">
                  <i
                    className="fas fa-times bg-teal-700 text-white p-1 rounded-full w-6 hover:bg-teal-900"
                    onClick={() => onClickDelete(index)}
                  />
                </div>
              </div>
            ))}
            {files.length === 0 && (
              <div className="flex flex-col items-center justify-center h-[90%]">
                <i className="fas fa-camera fa-5x text-gray-600"></i>
                <p className="text-gray-600">Aucune photo</p>
              </div>
            )}
          </div>
          <button onClick={onClickPublish} className={greenButton + " w-64 m-auto mt-4 sticky bottom-4"}>
            <i className="fas fa-paper-plane mr-2" />
            Publier l'avis {files.length === 0 && "sans photo"} !
          </button>
        </div>
      </div>
    </div>
  );
}
