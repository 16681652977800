import L from "leaflet";
import markerIcon from "../../assets/markers/position.svg";
const iconPerson = new L.DivIcon({
  html: `
  <div style="background-color: transparent">
    <svg width="20" height="20" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="19" cy="19" r="19" fill="#C0E1FF"/>
        <circle cx="19" cy="19" r="14.5" fill="#00C2FF" stroke="white"/>
    </svg>
    </div>

    `,
  iconRetinaUrl: markerIcon,
  popupAnchor: [-5, -5], 
//   tooltipAnchor: [10, 0],
    iconAnchor: [19, 19],
    iconSize: [0, 0],
});

export { iconPerson };
