import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService";
import { LogoutButton } from "./LogoutButton";
import { useTranslation } from "react-i18next";

export function BurgerMenu({ className }: { className?: string }) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  // Right top burger menu that shows when you click on the burger icon
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className={className}>
      <button
        className="bg-transparent p-2 rounded cursor-pointer  p-0 text-teal-700"
        onClick={handleShow}
        type="button"
        title="Menu"
      >
        <i className="fas fa-gear text-2xl" />
      </button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Nom d'utilisateur")} : {AuthService.getUserAuth()?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>Email: {AuthService.getUserAuth()!.email}</p>
          </div>
          <div className="display-flex">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={() => {
                navigate("/Contact");
              }}
            >
              Nous contacter
            </a>
          </div>
          <div className="display-flex">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={() => {
                navigate("/CGU");
              }}
            >
              CGU & Politique de confidentialité (Privacy Policy)
            </a>
          </div>
          <LogoutButton />
        </Modal.Body>
      </Modal>
    </div>
  );
}
