import { useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { AggregatedRestaurant } from "../services/RestaurantService";
import { greenButton } from "../services/TailwindClassService";
import { priceOptions, restaurantTypes } from "./AddRestaurantSteps/RateRestaurant";
import {useTranslation} from "react-i18next";


export function FilterModal({baseList, show, onChangedNewList}: {baseList: AggregatedRestaurant[], show: boolean, onChangedNewList: (newList: AggregatedRestaurant[]) => void}) {
    const [chosenCategories, setChosenCategories] = useState<string[]>([]); 
    const [chosenPrice, setChosenPrice] = useState<number[] | undefined>();
    const {t} = useTranslation();
    const filterList = () => {
        let newList = baseList.filter(restaurant => {
            if(chosenCategories.length === 0 || chosenCategories[0] === "") return true
            return chosenCategories.every(e => restaurant.categories.includes(e))
        })
        if(chosenPrice && chosenPrice.length > 0) {
            newList = newList.filter(restaurant => {
                return chosenPrice.includes(Math.round(restaurant.avgPrice * 10) / 10)
            })
        }
        onChangedNewList(newList)
    }
    
    return (
        <>
        <Modal show={show} onHide={filterList}>
            <Modal.Header closeButton>
                <Modal.Title>{t("Filtrer")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label>Catégories</label>
                <Select
                placeholder="Toutes les catégories"
                isMulti
                    options={restaurantTypes.map(e => ({value: e, label: e})) as any[]}
                    onChange={(selectedCategories) => {
                        setChosenCategories(selectedCategories.map(s => s.value) as any[] )
                    }}
                    value={chosenCategories.map(e => ({value: e, label: e === "" ? "Tous" : e})) as any[]}
                />
                 <label>{t("Prix")}</label>
                <Select
                placeholder="Tous les prix"
                isMulti
                    options={priceOptions as any[]}
                    onChange={(selectedCategories) => {
                        setChosenPrice(selectedCategories.map(s => s.value) as any[] )
                    }}
                    value={priceOptions.filter(p => chosenPrice?.includes(p.value)) as any[]}
                />
            </Modal.Body>
            <Modal.Footer>
                <button className={greenButton} onClick={filterList}>
                    {t("Filtrer")}
                </button>
            </Modal.Footer>
        </Modal>
        </>
    )
}
