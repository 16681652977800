import axios from "axios";
import { AggregatedRestaurant } from "./RestaurantService";
export type Friend = {
    id: string;
    name: string;
}

export type FriendProfile = Friend & {
    restaurantsPublished: {$values: AggregatedRestaurant[]};
}

export default new class FriendService {
    public async getCheatCode(): Promise<string> {
        const response = await axios.get("/User/cheatcode");
        return response.data;
    }

    public async addWithCheatCode(cheatCode: string): Promise<void> {
        await axios.post(`/User/friends/cheat/${cheatCode}`);
    }

    public async getFriends(): Promise<Friend[]> {
        const response = await axios.get("/User/friends");
        return response.data.$values;
    }

    public async getPendingFriendRequests(): Promise<Friend[]> {
        const response = await axios.get("/User/friends/pending");
        return response.data.$values;
    }

    public async searchFriend(name: string): Promise<Friend> {
        const response = await axios.get(`/User/friends/search/${name}`);
        return response.data;
    }

    public async addFriend(id: string): Promise<void> {
        await axios.post(`/User/friends/${id}`);
    }

    public async acceptFriendRequest(id: string): Promise<void> {
        await axios.post(`/User/friends/accept/${id}`);
    }

    public async removeFriend(friendId: string): Promise<void> {
        await axios.delete(`/User/friends/remove/${friendId}`);
    }
    public async getFriendProfile(friendId: string): Promise<FriendProfile> {
        const response = await axios.get(`/User/profile/${friendId}`);
        return response.data;
    }
}()