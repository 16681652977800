import { useEffect, useRef, useState } from "react";
import Radar from "radar-sdk-js";
import { Overlay, Popover, Spinner } from "react-bootstrap";
import AddressService, {
  AddressSearchResult,
  defaultLocationLatLon,
  SimpleAddress,
} from "../services/AddressService";
import { basicInput, greenButton } from "../services/TailwindClassService";
import {useTranslation} from "react-i18next";

interface AddressInputProps {
  defaultLatLong?: [number, number];
  onChange?: (loc: {
    latLong: [number, number];
    type: "manual" | "automatic";
  }) => any;
  onChangeWithAddress?: (
    address: SimpleAddress,
    latLong: [number, number]
  ) => any;
  placeHolder: string;
  input_id: string;
  search_type: "POI" | "ADDRESS" | "CITY";
  className?: string;
  closeTo?: { latLon: [number, number]; meters: number };
  geoLocEnabled?: boolean;
  city?: string;
  value?: string;
}

export function AddressInput({
  defaultLatLong,
  onChange,
  placeHolder,
  onChangeWithAddress,
  input_id,
  city,
  geoLocEnabled,
  search_type,
  closeTo,
  className,
  value
}: AddressInputProps) {
  const { t } = useTranslation();
  const [address, setAddress] = useState<SimpleAddress>({
    display_name: "",
    lat: 0,
    lon: 0,
    address: "",
  });
  const [addressesAutocomplete, setAddressesAutocomplete] = useState<
    AddressSearchResult[]
  >([]);
  const [addressSetAuto, setAddressSetAuto] = useState(false);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const ref = useRef(null);
  

  useEffect(() => {
   
    if (geoLocEnabled) {
      if (defaultLatLong) {
        Radar.reverseGeocode(
          {
            latitude: defaultLatLong[0],
            longitude: defaultLatLong[1],
          },
          function (err: any, result: any) {
            if (!err && !addressSetAuto) {
              setAddress({
                display_name:
                  (result.addresses[0].addressLabel ? (result.addresses[0].addressLabel + ", ") : "") +
                  result.addresses[0].city,
                lat: result.addresses[0].latitude,
                lon: result.addresses[0].longitude,
                address:
                  result.addresses[0].addressLabel +
                  ", " +
                  result.addresses[0].city,
              });
              setAddressSetAuto(true);
            }
          }
        );
      } else {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            Radar.reverseGeocode(
              {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
              function (err: any, result: any) {
                if (!err) {
                  if (search_type !== "CITY")
                    setAddress({
                      display_name:
                      (result.addresses[0].addressLabel ? (result.addresses[0].addressLabel + ", ") : "") +
                        result.addresses[0].city,
                      lat: result.addresses[0].latitude,
                      lon: result.addresses[0].longitude,
                      address:
                        result.addresses[0].addressLabel +
                        ", " +
                        result.addresses[0].city,
                    });
                  else
                    setAddress({
                      display_name:
                        result.addresses[0].city +
                        ", " +
                        result.addresses[0].country,
                      lat: result.addresses[0].latitude,
                      lon: result.addresses[0].longitude,
                      address:
                        result.addresses[0].city +
                        ", " +
                        result.addresses[0].country,
                    });
                  onChange!({
                    latLong: [
                      position.coords.latitude!,
                      position.coords.longitude,
                    ],
                    type: "automatic",
                  });
                  AddressService.setLatLong = [
                    position.coords.latitude!,
                    position.coords.longitude,
                  ];
                } else {
                  onChange!({
                    latLong: defaultLocationLatLon,
                    type: "automatic",
                  });
                }
              }
            );
          });
        }
      }
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if(value) {
      console.log(value);
      
      setAddress({
        display_name: value,
        lat: 0,
        lon: 0,
        address: "",
      });
    }
  }, [value])

  const setStateAddressAutoComplete = async (addressSaved: string) => {
    setSearchLoading(true);
    if (search_type === "ADDRESS" || search_type === "CITY") {
      await AddressService.findAddressByInput(
        input_id,
        addressSaved,
        (r) => {
          setAddressesAutocomplete(r);
          setSearchLoading(false);
        },
        search_type === "CITY"
      );
    } else {
      await AddressService.findPOIByInput(
        input_id,
        addressSaved,
        city!,
        (r) => {
          setAddressesAutocomplete(r);
          setSearchLoading(false);
        },
        closeTo
      );
    }
  };

  const onConfirmed = (addressFromSearch: SimpleAddress) => {
    // Use nominatim api to get the lat long with fetch

    setAddress(addressFromSearch);
    setShow(false);
    if (onChange)
      onChange({
        latLong: [addressFromSearch.lat, addressFromSearch.lon],
        type: "manual",
      });
    else if (onChangeWithAddress)
      onChangeWithAddress(addressFromSearch, [
        addressFromSearch.lat,
        addressFromSearch.lon,
      ]);
  };

  return (
    <div className={"flex justify-between"} ref={ref}>
      <div className="relative w-[100%]">
      <span className="flex absolute top-2 pl-2">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
          {search_type === "ADDRESS" && (
                      <span className="pt-[3px]">Adresse :</span>

            )}
      </span>
      <input
        className={"addressInput " + basicInput + " " + className + (search_type === "ADDRESS" ? " addressInputPL" : "")} 
        id={input_id}
        type="text"
        placeholder={placeHolder}
        value={ address.display_name}
        onChange={(e) => {
          const value = e.currentTarget.value;
          setAddress({
            ...address,
            display_name: value,
          });

          if (value.length >= 3 && !show) {
            setShow(true);
            setTarget(e.target as any);
          } else if (value.length < 3) setShow(false);

          if (value.length > 3) {
            setStateAddressAutoComplete(value);
          }
        }}
      />
      </div>
      
      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref}
        containerPadding={20}
      >
        <Popover
          id="popover-contained"
          className="w-full"
          style={{ maxWidth: "80%" }}
        >
          <Popover.Body>
            {search_type === "POI" &&
              !searchLoading &&
              ((addressesAutocomplete.length === 0 && (
                <div className="text-center">
                  <p className="text-xl">
                    Veuillez rentrer vous-mêmes l'addresse...{" "}
                  </p>
                  <button
                    className={greenButton}
                    onClick={() => {
                      onChangeWithAddress!(address, defaultLocationLatLon);
                      setShow(false);
                    }}
                  >
                    {t("Continuer")}
                  </button>
                </div>
              )) || (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  className="text-center"
                  onClick={() => setAddressesAutocomplete([])}
                >
                  {" "}
                  {t("Restaurant introuvable")} ?{" "}
                </a>
              ))}
            {searchLoading && (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            )}

            {addressesAutocomplete.map((a: any) => (
              <div
                key={JSON.stringify(a)}
                className="flex items-center justify-between px-4 py-2 cursor-pointer"
                onClick={() => {
                  onConfirmed(a);
                }}
              >
                <div className="flex items-center">
                <i className="fa-solid fa-location-dot mr-2"></i>
                  
                  <span className="result-address">
                    {a.display_name  + ", " + a.country 
                      }
                  </span>
                </div>
              </div>
            ))}
          </Popover.Body>
        </Popover>
      </Overlay>
     
    </div>
  );
}
