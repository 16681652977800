import { useEffect, useState } from "react";
import { greenButton, neutralInput } from "../services/TailwindClassService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FullPageCard } from "../components/FullPageCard";
import AuthService from "../services/AuthService";
import { ForgotPasswordModal } from "../components/ForgotPasswordModal";
import { Modal } from "react-bootstrap";
import { GoogleLoginButtonComponent } from "../components/GoogleLogin";

export function Login() {
  let navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [showSignupFirst, setShowSignupFirst] = useState(false);

  // use location to get query param named from
  const [searchParams] = useSearchParams();


  const login = () => {
    AuthService.authenticate({ email, password }, (valid) => {
      // if there is a query param "from" then navigate to that page
      const from = window.location.search.split("from=")[1];
      if (valid && from) {
        navigate(from);
      }
      if (valid) {
        navigate("/discover");
      } else {
        setError("Email ou mot de passe incorrect");
      }
    });
  };

  useEffect(() => {
    if(AuthService.getUserAuth() !== undefined) {
      // reload window
      window.location.reload();
    }
  }, [])
  

  return (
    <div>
      {/* Modal in case of should_signup_first */}
      <Modal show={showSignupFirst} onHide={() => setShowSignupFirst(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Vous devez vous inscrire avant de vous connecter
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Vous devez vous inscrire avant de vous connecter
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className={greenButton} onClick={() => setShowSignupFirst(false)}>
            Fermer
          </button>
        </Modal.Footer>
      </Modal>

      <FullPageCard>
        <div className={"text-center"}>
          <h3 className={"text-xl"}>Connexion</h3>
        </div>
        <div className="flex items-center justify-around mt-4">
            <GoogleLoginButtonComponent />
          </div>
        <div className={"text-left ml-5 mr-5 mt-4"}>
          {error && (
            <div className={"text-red-500"}>
              <p>{error}</p>
            </div>
          )}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Adresse email
            </label>
            <input
              value={email}
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
              className={neutralInput}
              id="email"
              type="text"
              placeholder="example@example.org"
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Mot de passe
            </label>
            <input
              value={password}
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
              className={neutralInput}
              id="password"
              type="password"
              placeholder="******************"
            />
          </div>

          <div className="flex items-center justify-between flex-wrap">
            <button
              className={greenButton + " m-auto"}
              type="button"
              id="login-button"
              onClick={login}
            >
              Connexion
            </button>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="inline-block align-baseline font-bold text-sm text-teal-700 transition hover:text-teal-900 cursor-pointer m-auto text-md"
              onClick={() => {
                navigate("/register" + (searchParams.has("from") ? "?from=" + searchParams.get("from") : ""));
              }}
            >
              Pas encore inscrit ?
            </a>
          </div>
          
        </div>
        <ForgotPasswordModal />
      </FullPageCard>
    </div>
  );
}
