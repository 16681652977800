import {Route, Routes} from "react-router-dom";
import {Home} from "../views/Home";
import {Register} from "../views/Register";
import {Discover} from "../views/Discover";
import ProtectedRoute, {ProtectedRouteProps} from "./PrivateRoute";
import {Login} from "../views/Login";
import { FriendsPage } from "../views/FriendsPage";
import { AddRestaurant } from "../views/AddRestaurant";
import AuthService from "../services/AuthService";
import { CGU } from "../views/CGU";
import { ContactForm } from "../views/ContactForm";
import { UserProfile } from "../views/UserProfile";

export function BackFoodRoutes() {


    let defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
        isAuthenticated: AuthService.getUserAuth() !== undefined,
        authenticationPath: '/login',
    };


    return (
        <Routes>
            <Route path={"/"} element={<Home/>}/>
            <Route path={"/register"} element={<Register/>}/>
            <Route path={"/login"} element={<Login/>}/>
            <Route path={"/CGU"} element={<CGU/>}/>
            <Route path={"/Contact"} element={<ContactForm/>}/>




            <Route path={"discover"} element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Discover/>}/>}/>
            <Route path={"profile"} element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<UserProfile/>}/>}/>
            <Route path={"friends"} element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<FriendsPage/>}/>}/>
            <Route path={"add_restaurant"} element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<AddRestaurant/>}/>}/>
 
            
        </Routes>
    )
}