import { MapContainer, Marker, Popup, TileLayer, Tooltip } from "react-leaflet";

import { useEffect, useState } from "react";
import { Map as LMap } from "leaflet";
import { restaurantMarkerIcon } from "./map/RestaurantMarker";
import { iconPerson } from "./map/PositionMarker_";
import { AggregatedRestaurant } from "../services/RestaurantService";
import { RestaurantDetailsModal } from "./RestaurantDetailsModal";
import { defaultLocationLatLon } from "../services/AddressService";
import axios from "axios";
import { greenButton } from "../services/TailwindClassService";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { beerMarker } from "./map/BeerMarker";
import { restaurantAndBarMarker } from "./map/RestaurantAndBarMarker";

export interface RestaurantPublishedModel {
  name: string;
  location: [number, number];
  // eslint-disable-next-line max-len
  address: string;

  computed?: {
    // eslint-disable-next-line max-len
    categories: (
      | "Fast-food"
      | "Bar"
      | "Restaurant"
      | "Américain"
      | "Asiatique"
      | "Français"
      | "Européen"
      | "Oriental"
      | "Sud-Américain"
      | "Bar-Dansant"
      | "Africain"
      | "Nordique"
    )[];
    descriptions: {
      text: string;
      publishedAt: string;
      publishedByName: string;
    }[];
    publishAt: Date;
    publishBy: any;
    rating: {
      price: number;
      quality: number;
      service: number;
      ambience: number;
    };
  };

  publishments: {
    // eslint-disable-next-line max-len
    categories: (
      | "Fast-food"
      | "Bar"
      | "Restaurant"
      | "Américain"
      | "Asiatique"
      | "Français"
      | "Européen"
      | "Oriental"
      | "Sud-Américain"
      | "Bar-Dansant"
      | "Africain"
      | "Nordique"
    )[];
    description: string;
    publishAt: Date;
    publishBy: any;
    rating: {
      price: number;
      quality: number;
      service: number;
      ambience: number;
    };
  }[];
}
interface CenteredMapProps {
  onLatLongFound?: (latLong: [number, number]) => any;
  position?: [number, number];
  mapCenterPosition?: [number, number];
  restaurants?: AggregatedRestaurant[];
}

export function CenteredMap({
  position,
  restaurants,
  mapCenterPosition,
}: CenteredMapProps) {
  const navigate = useNavigate();
  let [locationToShow, setLocationToShow] = useState(defaultLocationLatLon);
  let [mapCurrentPos, setMapCurrentPos] = useState(defaultLocationLatLon);
  const [searchParams, setSearchParams] = useSearchParams();
  let [showToolTips, setShowTooltips] = useState(false);

  useEffect(() => {
    if (restaurants && searchParams.has("restaurant")) {
      let restaurant = restaurants.find(
        (restaurant) => restaurant.id === searchParams.get("restaurant")
      );
      if (restaurant) {
        console.log(restaurant);

        setCurrentRestaurant(restaurant);

        searchParams.delete("restaurant");
        setSearchParams(searchParams);
      }
    }
  }, [restaurants, searchParams, setSearchParams]);

  let [clickedPlace, setClickedPlace] = useState<
    | {
        latLng: [number, number];
        name: string;
        city: string;
      }
    | undefined
  >(undefined);
  let [currentRestaurant, setCurrentRestaurant] = useState<
    AggregatedRestaurant | undefined
  >();

  // Request permission to use geolocation

  let [map, setMap] = useState(undefined as LMap | undefined);

  useEffect(() => {
    if (position !== locationToShow) {
      if (position && position !== defaultLocationLatLon) {
        setLocationToShow(position);
      } else {
        setLocationToShow(defaultLocationLatLon);
      }
    }
  }, [locationToShow, position]);

  //on component mounted and when map is defined then fly to the current position
  useEffect(() => {
    if (map) {
      if (mapCurrentPos !== defaultLocationLatLon) {
        map.flyTo(mapCurrentPos, 13);
      } else {
        map.flyTo(mapCenterPosition || defaultLocationLatLon, 13);
      }
    }
  }, [map, mapCurrentPos, mapCenterPosition]);

  useEffect(() => {
    if (map) {
    }
  });

  useEffect(() => {
    if (JSON.stringify(mapCenterPosition) !== JSON.stringify(mapCurrentPos)) {
      setMapCurrentPos(mapCenterPosition!);
      map?.flyTo(
        { lat: mapCenterPosition![0], lng: mapCenterPosition![1] },
        13
      );
    }
  }, [map, mapCenterPosition, mapCurrentPos]);

  useEffect(() => {
    if (JSON.stringify(mapCenterPosition) !== JSON.stringify(mapCurrentPos)) {
      setMapCurrentPos(mapCenterPosition!);
      map?.flyTo(
        { lat: mapCenterPosition![0], lng: mapCenterPosition![1] },
        13
      );
    }
  }, [map, mapCenterPosition, mapCurrentPos]);

  let tooltipThreshold = 15;
  let mapInstantiated = (newMap: LMap) => {
    setMap(newMap);
    let lastLocation = [0, 0];
    newMap.on("zoomend", function () {
      if (newMap.getZoom() < tooltipThreshold) {
        setShowTooltips(false);
      } else {
        setShowTooltips(true);
      }
    });

    newMap.addEventListener("click", (e: any) => {
      e.originalEvent.preventDefault();

      if (
        lastLocation[0] !== e.latlng.lat &&
        lastLocation[1] !== e.latlng.lng
      ) {
        var popLocation = e.latlng;

        lastLocation = [popLocation.lat, popLocation.lng];

        axios
          .get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${popLocation.lat}&lon=${popLocation.lng}`
          )
          .then((res) => {
            let city = res.data.address.city;
            if (!city) {
              city = res.data.address.town;
            }
            if (!city) {
              city = res.data.address.village;
            }
            if (!city) {
              city = res.data.address.county;
            }
            if (!city) {
              city = res.data.address.city_district;
            }
            if (res.data.address.amenity) {
              setClickedPlace({
                name: res.data.address.amenity,
                latLng: [popLocation.lat, popLocation.lng],
                city: city,
              });
            } else setClickedPlace(undefined);
          });
      }
    });
  };

  function findMarker(restaurant: AggregatedRestaurant) {
    let normalizedCats = restaurant.categories.map(c => c.toLowerCase().trim())
    if (normalizedCats.includes("bar") && normalizedCats.includes("restaurant")) {
      return restaurantAndBarMarker;
    } else if (normalizedCats.includes("bar")) {
      return beerMarker;
    } else if (normalizedCats.includes("restaurant")) {
      return restaurantMarkerIcon;
    }

    return restaurantAndBarMarker;
  }

  return (
    <div className="h-[90%] relative">
      {currentRestaurant && (
        <RestaurantDetailsModal
          show={currentRestaurant !== undefined}
          restaurant={currentRestaurant}
          onClose={() => {
            setCurrentRestaurant(undefined);
          }}
        />
      )}

      <MapContainer
        className={"absolute top-0 bottom-0 w-[100%] z-0"}
        center={[locationToShow[0], locationToShow[1]]}
        zoom={13}
        maxZoom={20}
        whenCreated={mapInstantiated}
        whenReady={() => {}}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
          maxZoom={20}
          maxNativeZoom={20}
        />
        {clickedPlace && (
          <Popup position={clickedPlace.latLng}>
            <div>{clickedPlace.name}</div>
            <button
              className={greenButton}
              onClick={() => {
                navigate(
                  "/add_restaurant?name=" +
                    clickedPlace!.name +
                    "&lat=" +
                    clickedPlace!.latLng[0] +
                    "&lng=" +
                    clickedPlace!.latLng[1] +
                    "&city=" +
                    clickedPlace!.city
                );
              }}
            >
              Noter
            </button>
          </Popup>
        )}
        {position && position !== defaultLocationLatLon && (
          <Marker
            position={[locationToShow[0], locationToShow[1]]}
            icon={iconPerson}
          >
            <Popup>Votre position</Popup>
          </Marker>
        )}

        {restaurants
          ? restaurants?.map((restaurant: AggregatedRestaurant) => {
              return (
                <Marker
                  position={restaurant.location}
                  icon={
                    restaurantMarkerIcon
                  }
                  key={JSON.stringify(restaurant)}
                  title={restaurant.name}
                >
                  {showToolTips ? (<Tooltip offset={[-10, 8]} direction="bottom" permanent>
                    {restaurant.name}
                  </Tooltip>) : undefined}
                  
                  <Popup>
                    {restaurant.name}
                    <br />
                    <p className="text-2xl text-center center">
                      {Math.round(restaurant.average * 10) / 10}
                      /10
                      <br />
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        href="#"
                        className="text-sm"
                        onClick={() => {
                          setCurrentRestaurant(restaurant);
                        }}
                      >
                        Voir
                      </a>
                    </p>
                  </Popup>
                </Marker>
              );
            })
          : undefined}
      </MapContainer>
    </div>
  );
}
