// Const that has all cities in europe

import { useEffect, useState } from "react";
import AddressService, {
  defaultLocationLatLon,
} from "../../services/AddressService";
import { PublishRestaurantModel } from "../../services/RestaurantService";
import { basicInput, greenButton } from "../../services/TailwindClassService";
import { AddressInput } from "../AddressInput";
import { useTranslation } from "react-i18next";
import Radar from "radar-sdk-js";
import { useLocation } from "react-router-dom";

export function LocateRestaurant(
  restaurantProp: PublishRestaurantModel & {
    next: (restaurant: PublishRestaurantModel) => void;
  }
) {
  const loc = useLocation();
  const { t } = useTranslation();
  // forms that asks for restaurant city and restaurant name
  const [restaurant, setRestaurant] =
    useState<PublishRestaurantModel>(restaurantProp);
  const [city, setCity] = useState({
    display_name: "",
    lat: 0,
    lon: 0,
  });
  useEffect(() => {
    const searchLocationUrl = new URLSearchParams(loc.search);

    if (searchLocationUrl.has("name")) {
      const lat = searchLocationUrl.get("lat");
      const lon = searchLocationUrl.get("lng");
      const latLng: [number, number] = [parseFloat(lat!), parseFloat(lon!)];
      const name = searchLocationUrl.get("name")!;
      const city = searchLocationUrl.get("city")!;
      console.log("going to reverse", latLng, decodeURI(name), city);
      
      reverseAddress(latLng, {
        ...restaurant,
        name,
        city,
        location: latLng,
      });

    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reverseAddress = (latLong: [number, number], rest: PublishRestaurantModel) => {
    Radar.reverseGeocode(
      {
        latitude: latLong[0],
        longitude: latLong[1],
      },
      function (err: any, result: any) {
        setRestaurant({
          ...rest,
          address:
            (result.addresses[0].addressLabel
              ? result.addresses[0].addressLabel + ", "
              : "") +
            result.addresses[0].postalCode +
            " " +
            result.addresses[0].city,
        });
      }
    );
  };

  const nextStep = () => {
    if (restaurant.name && restaurant.address) {
      restaurantProp.next(restaurant);
    } else {
      alert("Merci de renseigner tous les champs");
    }
  };

  return (
    <div>
      <label>{t("Ville")}</label>
      <AddressInput
        placeHolder="Rechercher une ville"
        input_id="city_lookup"
        search_type="CITY"
        value={restaurant.city}
        onChangeWithAddress={(address, latLong) => {
          AddressService.setLatLong = latLong;
          setCity({
            display_name: address.display_name,
            lat: latLong[0],
            lon: latLong[1],
          });
        }}
      />
      <label>{t("Nom du restaurant")}</label>
      <AddressInput
        placeHolder={t("Nom du restaurant")}
        closeTo={{
          latLon: [city.lat, city.lon],
          meters: 20000,
        }}
        value={restaurant.name}
        city={city.display_name}
        input_id="resto_name_lookup"
        search_type="POI"
        defaultLatLong={[city.lat, city.lon]}
        onChangeWithAddress={(address, latLong) => {
          if (latLong === defaultLocationLatLon || latLong === [0, 0]) {
            setRestaurant({
              ...restaurant,
              address: address.address,
              location: latLong,
              name: address.display_name,
            });
          } else {
            setRestaurant({ ...restaurant, address: "... Chargement" });
            reverseAddress(latLong, {
              ...restaurant,
              name: address.display_name,
              address: "... Chargement",
              location: latLong,
            });
          }
        }}
      />

      <label>{t("Adresse du restaurant")}</label>
      <input
        className={basicInput}
        placeholder="Adresse"
        type="text"
        value={restaurant.address}
        onChange={(e) => {
          setRestaurant({ ...restaurant, address: e.currentTarget.value });
        }}
      />
      <button className={greenButton} onClick={nextStep}>
        {t("Passer à l'étape suivante")}
        {/* Right arrow icon fa */}
        <i className="fa fa-arrow-right ml-4" />
      </button>
    </div>
  );
}
