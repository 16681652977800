import { useEffect, useState } from "react";
import AddressService, { SimpleAddress } from "../../services/AddressService";
import { greenButton, redButton } from "../../services/TailwindClassService";

export function PropositionAddresse({
  address,
  next,
}: {
  address: string;
  next: (address: SimpleAddress) => any;
  previous: () => any;
}) {
  const [proposedAddress, setProposedAddress] = useState<
    SimpleAddress | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    AddressService.findAddressByInput("proposition", address, (a) => {
      setIsLoading(false);
      setProposedAddress(a[0]);
    });
  }, [address]);

  return (
    <>
      <p className="text-3xl">Cette adresse est-elle la bonne ?</p>
      <div>
        {isLoading ? (
          <div className="animate-pulse flex">
            <div className="flex-1">
              <div className="h-1 bg-green-600"></div>
            </div>
          </div>
        ) : undefined}
      </div>

      <p className="text-2xl">{proposedAddress?.display_name}</p>
      <button
        className={redButton}
        onClick={() => {
          next(proposedAddress!);
        }}
      >
        Non
      </button>
      <button
        className={greenButton}
        onClick={() => {
          next(proposedAddress!);
        }}
      >
        Oui
      </button>
      
    </>
  );
}
