import axios from "axios";

export interface PublishRestaurantModel {
  name: string;
  restaurantId?: string;
  location: [number, number];
  restaurantRef?: string;
  description: string;
  illustrations: string[];
  city?: string;
  // eslint-disable-next-line max-len
  categories: (
    | "Fast-food"
    | "Bar"
    | "Restaurant"
    | "Américain"
    | "Asiatique"
    | "Français"
    | "Européen"
    | "Oriental"
    | "Sud-Américain"
    | "Bar-Dansant"
    | "Africain"
    | "Nordique"
  )[];
  address: string;
  ratings: {
    price: number;
    quality: number;
    service: number;
    ambiance: number;
  };
}
export interface AggregatedRestaurant {
  id: string;
  name: string;
  location: [number, number];
  address: string;
  city: string;
  avgAmbiance: number;
  avgPrice: number;
  avgService: number;
  avgQuality: number;
  average: number;
  descriptions: {$values: Description[]};
  illustrations: string[];
  categories: string[];
}

export interface Description {
    publishAt: string;
    description: string;
    authorName: string;
}

export default new (class RestaurantService {
  async getAllRestaurants(): Promise<AggregatedRestaurant[]> {
    const response = await axios.get("Restaurant");
    return response.data.$values;
  }

  async publishRestaurant(restaurant: PublishRestaurantModel): Promise<void> {
    await axios.post("Restaurant/publish", restaurant);
  }
})();
