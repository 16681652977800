import { useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { PublishRestaurantModel } from "../../services/RestaurantService";
import { greenButton } from "../../services/TailwindClassService";
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next';

// i18n.on('languageChanged', language => {
//   i18n.setDefaultNamespace('fr')
// })

export const priceOptions = [
  { value: 0, label: `€ (petit budget)` },
  { value: 1, label: `€€ (abordable)` },
  { value: 2, label: `€€€ (pas tous les jours)` },
  { value: 3, label: `€€€€ (cher)` },
  { value: 4, label: `€€€€€ (très cher)` },
];

export const restaurantTypes = [
  "Fast-food",
  "Bar",
  "Restaurant",
  "Américain",
  "Asiatique",
  "Français",
  "Espagnol",
  "Oriental",
  "Sud-Américain",
  "Bar-Dansant",
  "Africain",
  "Nord-Africain",
  "Italien",
  "Grec",
  "Tapas"
];
export function RateRestaurant(
  restaurantOrigin: PublishRestaurantModel & {
    onSubmit: (restaurant: PublishRestaurantModel) => void;
  }
) {
  const { t } = useTranslation();
  const [restaurant, setRestaurant] =
    useState<PublishRestaurantModel>(restaurantOrigin);
  const [showPriceTip, setShowPriceTip] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  

  const onRecommander = () => {


    // If the restaurant has no categories show an error
    if (restaurant.categories.length === 0) {
      setErrors([...errors, "Veuillez choisir au moins une catégorie"]);
      return;
    }
    if (!restaurant.description || restaurant.description.length < 10) {
      setErrors([...errors, "Veuillez entrer une description (10 caractères minimum)"]);
      return;
    }


    let qualite = restaurant.ratings.quality;
    let service = restaurant.ratings.service;
    let ambiance = restaurant.ratings.ambiance;

    if (qualite + service + ambiance >= 0) {
      restaurantOrigin.onSubmit(restaurant);
    }
  };
  return (
    <>
      {/* Error modal */}
      <Modal show={errors.length > 0}>
        <Modal.Header>
          <Modal.Title>Erreur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {errors.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={greenButton}
            onClick={() => setErrors([])}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>


      {/* Modal that describes price levels */}
      <Modal
        show={showPriceTip}
        onHide={() => {
          setShowPriceTip(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="text-2xl">
              Informations sur <span className="text-teal-700">le prix</span>
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <Trans i18nKey={"INFO_PRIX"}>
              Le prix va permettre à tes amis de pouvoir trouver un restaurant
              dans leur budget.
              <br />
              Cette page est juste à titre indicatif. Nous te conseillons toujours
              de suivre <span className="text-teal-700"> ton instinct</span>.
            </Trans>
          </p>
          <p>
            Description des fourchettes : <br />
            <ul>
              <li>{t("PRIX_SMALL_DESC")}</li>
              <li>{t("PRIX_ABORDABLE_DESC")}</li>
              <li>{t("PRIX_PAS_TOUS_LES_JOURS")}</li>
              <li>{t("PRIX_CHER")}</li>
              <li>{t("PRIX_TRES_CHER")}</li>

            </ul>
          </p>
        </Modal.Body>
      </Modal>

      <div className="flex flex-col ">
      <Select
          id="categories"
          className="mb-4"
          placeholder={t("Catégories")}
          options={restaurantTypes.map((type) => ({ value: type, label: type })) as any[]}
          isMulti
          value={restaurant.categories.map((type) => ({
            value: type,
            label: type,
          }))}
          onChange={(e) => {
            setRestaurant({
              ...restaurant,
              categories: e.map((type) => type.value),
            });
          }}
        />
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="price"
        >
          {t("Prix")}
          {/* Information icon */}
          <span
            className="inline-block ml-1 text-sm text-gray-500 cursor-pointer"
            onClick={() => {
              setShowPriceTip(true);
            }}
          >
            <i className="fas fa-info-circle text-teal-700 " />
          </span>
        </label>

        <Select
          id="price"
          className="mb-4"
          placeholder={t("Prix")}
          isMulti={false}
          options={priceOptions as any[]}
          value={priceOptions[restaurant.ratings.price] as any}
          onChange={(e: any) => {
            setRestaurant({ ...restaurant, ratings: {...restaurant.ratings, price: e.value }});
          }}
        />
      </div>
      <div className="flex flex-col">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="quality"
        >
          Qualité
        </label>
        <input
          placeholder="Service"
          className="input-range"
          aria-orientation="vertical"
          type="range"
          step="0.1"
          defaultValue={5}
          min="1"
          max="10"
          value={restaurant.ratings.quality}
          onChange={(e) => {
            setRestaurant({
              ...restaurant,
              ratings: {
                ...restaurant.ratings,
                quality: Number(e.target.value),
              },
            });
          }}
        />
        {/* flex line with two opposite texts */}
        <div className="inline-flex justify-between">
          <span className="text-red-600 text-sm w-30">
            <strong>Vraiment pas bon</strong>
          </span>
          <span className="text-green-600 text-sm w-20">
            <strong>Super bon</strong>
          </span>
        </div>
      </div>
      <div className="flex flex-col">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="service"
        >
          Service
        </label>
        <input
          value={restaurant.ratings.service}
          onChange={(e) => {
            setRestaurant({
              ...restaurant,
              ratings: {
                ...restaurant.ratings,
                service: Number(e.target.value),
              },
            });
          }}
          placeholder="Service"
          className="input-range"
          aria-orientation="vertical"
          type="range"
          step="0.1"
          defaultValue={5}
          min="1"
          max="10"
        ></input>
        <div className="inline-flex justify-between">
          <span className="text-red-600 text-sm w-30">
            <strong>Désagréable</strong>
          </span>
          <span className="text-green-600 text-sm w-20">
            <strong>Un amour !</strong>
          </span>
        </div>
      </div>
      <div className="flex flex-col">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="ambience"
        >
          Ambiance
        </label>
        <input
          placeholder="Service"
          className="input-range"
          aria-orientation="vertical"
          type="range"
          step="0.1"
          defaultValue={5}
          min="1"
          max="10"
          value={restaurant.ratings.ambiance}
          onChange={(e) => {
            setRestaurant({
              ...restaurant,
              ratings: {
                ...restaurant.ratings,
                ambiance: Number(e.target.value),
              },
            });
          }}
        />
        <div className="inline-flex justify-between">
          <span className="text-red-600 text-sm w-30">
            <strong>Inadapté</strong>
          </span>
          <span className="text-green-600 text-sm w-20">
            <strong>Parfait</strong>
          </span>
        </div>
      </div>
      {/* Comment blocked size text area */}
      <div className="flex flex-col">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="comment"
        >
          Commentaire
          {/* Mandatory start */}
          <span className="text-red-600">*</span>
        </label>
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
          id="comment"
          placeholder="Commentaire"
          value={restaurant.description}
          onChange={(e) => {
            setRestaurant({
              ...restaurant,
              description: e.target.value,
            });
          }}
        ></textarea>
        

        <button className={greenButton} onClick={onRecommander}>
        <i className="fa fa-camera mr-2" />
          Passer aux photos
        </button>
      </div>
    </>
  );
}
