import Logo from "../assets/BackfooderLogoV2.png";

import { useEffect, useState } from "react";
import { CenteredMap } from "../components/CenteredMap";
import { AddressInput } from "../components/AddressInput";
import { RestaurantsList } from "../components/RestaurantsList";

import LoaderService from "../services/LoaderService";
import AuthService from "../services/AuthService";
import RestaurantService, {
  AggregatedRestaurant,
} from "../services/RestaurantService";
import { FilterModal } from "../components/FilterModal";
import {
  greenButtonRounded,
} from "../services/TailwindClassService";
import { defaultLocationLatLon } from "../services/AddressService";
import Radar from "radar-sdk-js";
import {useTranslation} from "react-i18next";
 
export function Discover() {
  const { t } = useTranslation();

  const [restaurants, setRestaurants] = useState<AggregatedRestaurant[]>([]);
  const [filteredRestaurant, setFilteredRestaurant] =
    useState<AggregatedRestaurant[]>(restaurants);

  const [chosenView, setChosenView] = useState("map" as "map" | "list");
  const [mapCenter, setMapCenter] = useState(defaultLocationLatLon);

  const [latLongFound, setLatLongFound] = useState(
    undefined as [number, number] | undefined
  );

  const [showFilterModal, setShowFilterModal] = useState(false);

  useEffect(() => {
    LoaderService.onLoad!(true);
    Radar.setUserId(AuthService.getUserAuth()?.id);
    RestaurantService.getAllRestaurants().then((restaurants) => {
      setRestaurants(restaurants);
      setFilteredRestaurant(restaurants);
      LoaderService.onLoad!(false);
    });
  }, []);

  return (
    <div>
      <div className="top min-h-[7vh] h-[7vh]">
        <div className={"inline-flex"}>
          <img alt="Backfooder Logo" className={" min-h-[7vh] h-[7vh]"} src={Logo} />
        </div>
        {/* <h3 className=" m-0 text-[1rem]">
          Bienvenu(e)
          <span className="ml-2 inline-flex h-10 pt-2 overflow-x-hidden overflow-y-hidden animate-type group-hover:animate-type-reverse whitespace-nowrap text-brand-accent will-change text-teal-700">
            {AuthService.getUserAuth()?.name} 🤤
          </span>
        </h3> */}
      </div>

      <div className={" mt-0 space-x-5"}>
        <div className={"flex flex-column h-[89vh] max-h[89vh] m-auto relative"}>
          <div
            className="flex justify-center align-middle pt-2"
            style={{ backgroundColor: "#FEFBEC" }}
          >

            <div className="w-[90%] md:w-2/5">
              <AddressInput
                input_id="discover-location"
                search_type="ADDRESS"
                className="mb-2"
                placeHolder={t("Lieu actuel")}
                defaultLatLong={latLongFound}
                geoLocEnabled
                onChange={(latLong) => {
                  if (
                    mapCenter === defaultLocationLatLon ||
                    latLong.type === "manual"
                  ) {
                    setMapCenter(latLong.latLong);

                    setLatLongFound(latLong.latLong);
                  } else {
                    setLatLongFound(latLong.latLong);
                  }
                }}
              />
            </div>
          </div>
          {/* <div className="w-full">
            <div
              id="bottom-navigation"
              className="block inset-x-0 bottom-0 z-10 bg-white shadow h-12"
            >
              <div id="tabs" className="flex justify-between">
                <div
                  onClick={() => {
                    setChosenView("map");
                  }}
                  className={"w-full focus:text-teal-900 hover:text-teal-700 justify-center inline-block text-center pt-1 pb-1 cursor-pointer transition" + (chosenView === "map" ? " text-teal-700": "")}
                >
                  <i className="fas fa-map fa-l"></i>
                  <div className="leading-none">Carte</div>
                </div>
                <div
                  onClick={() => {
                    setChosenView("list");
                  }}
                  className={"w-full focus:text-teal-900 hover:text-teal-700 justify-center inline-block text-center pt-1 pb-1 cursor-pointer transition" + (chosenView === "list" ? " text-teal-700": "")}
                >
                  <i className="fas fa-list fa-l"></i>
                  <div className="leading-none">{t("Liste")}</div>
                </div>
              </div>
            </div>
          </div> */}
          <FilterModal
            baseList={restaurants}
            show={showFilterModal}
            onChangedNewList={(newList) => {
              setFilteredRestaurant(newList);
              setShowFilterModal(false);
            }}
          />

          {(chosenView === "map" && (
            <CenteredMap
              mapCenterPosition={mapCenter}
              onLatLongFound={(e) => {
                setLatLongFound(e);
              }}
              position={latLongFound}
              restaurants={filteredRestaurant}
            />
          )) || (
            <RestaurantsList
              restaurants={filteredRestaurant}
              baseLatLong={latLongFound}
            />
          )}
        </div>

        <div className=" w-[80vw] lg:w-[20vw] h-8 pt-none leading-none mb-4 mt-4 absolute bottom-[10vh] right-[10vw] lg:right-[40vw] text-xl  ">
          {chosenView === "map" && (
            <button
              className={greenButtonRounded + " rounded-r-none"}
              onClick={() => {
                setChosenView("list");
              }}
            >
              <i className="fas fa-list mr-2"></i>
              {t("Liste")}
            </button>
          )}
          {chosenView === "list" && (
            <button
              className={greenButtonRounded + " rounded-r-none"}
              onClick={() => {
                setChosenView("map");
              }}
            >
              Carte
              <i className="fas fa-map ml-2"></i>
            </button>
          )}
          <button
            className={greenButtonRounded + " rounded-l-none border-l-2 "}
            // style={{borderRadius: "50%!important"}}
            onClick={() => {
              setShowFilterModal(true);
            }}
          >
            <i className="fas fa-filter mr-2"></i>
            {t("Filtrer")}
          </button>
        </div>
      </div>
    </div>
  );
}

