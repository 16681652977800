import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import Radar from "radar-sdk-js"

import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:5000/";
  Radar.initialize("prj_test_pk_6a4a995c4a8de2cc0663ea7e0694ee4dd6a81213");
} else {
  axios.defaults.baseURL = "https://api.backfooder.fr/";
  Radar.initialize("prj_live_pk_5aaf71cc551bf0cc17f8a56771b52aec63984baf");
}
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();

reportWebVitals();
