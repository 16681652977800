import { DateTime } from "luxon";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AggregatedRestaurant } from "../services/RestaurantService";
import { greenButton, redButton } from "../services/TailwindClassService";
import RatingAmbiance from "../assets/rating/rating-happy.svg";
import RatingPrice from "../assets/rating/rating-price.svg";
import RatingService from "../assets/rating/rating-service.svg";
import RatingQuality from "../assets/rating/rating-quality.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import axios from "axios";
import SmartImage from "./SmartImage";

export function RestaurantDetailsModal({
  restaurant,
  show,
  onClose,
}: {
  restaurant: AggregatedRestaurant;
  show: boolean;
  onClose: () => void;
  onShow?: () => void;
}) {
  const { t } = useTranslation();
  const [linkCopied, setLinkCopied] = useState(false);

  const share = () => {
    // Is it a mobile
    console.log("share", window.navigator.userAgent);
    if (window.navigator.userAgent.indexOf("Mobile") !== -1) {
      navigator.share({
        title: restaurant.name,
        text: restaurant.address,
        url: "https://backfooder.fr/discover?restaurant=" + restaurant.id,
      });
    } else {
      navigator.clipboard.writeText(
        "https://backfooder.fr/discover?restaurant=" + restaurant.id
      );
      setLinkCopied(true);
    }
    // Is it a desktop
  };

  const navigate = useNavigate();
  return (
    <Modal show={show}>
      <Modal.Header className="w-[100%]">
        <Modal.Title className="w-[100%]">
          <div className="flex justify-between w-[100%]">
            <div className="">{restaurant?.name}</div>
            <div className="">
              <div
                onClick={share}
                className="m-auto text-teal-700 border-1  rounded-full w-12 text-center  border-teal-700 hover:bg-teal-700 hover:text-white cursor-pointer transition"
              >
                <i className="fa-solid fa-share text-lg"></i>{" "}
              </div>
              {linkCopied && (
                <div className="text-teal-700 text-sm">{t("Lien copié !")}</div>
              )}
            </div>
          </div>
          <span className="text-sm">
            <a
              rel="noreferrer"
              href={
                "https://www.google.com/maps/search/" +
                encodeURI(restaurant?.name + " " + restaurant.address)
              }
              target="_blank"
            >
              {restaurant.address}
            </a>
          </span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div style={{ maxHeight: "55vh", overflowY: "auto", overflowX: "hidden" }}>
          {/* Global average rating as title */}
          <h3 className="text-center">
            {Math.round(restaurant.average * 10) / 10} / 10
          </h3>
          {/* flex div with 4 icons (ambiance, service, prix, qualité) */}
          <div className="flex flex-row justify-around">
            <div className="flex flex-col w-full text-center">
              <img
                src={RatingPrice}
                className="w-[60px] m-auto"
                alt="Ambiance emoji"
              />
              {/* <i className="fa-solid fa-money-bill-1 text-4xl"></i> */}
              {t("Prix")} <br />
              {"€".repeat(Math.round(restaurant?.avgPrice + 1))}
            </div>
            <div className="flex flex-col w-full text-center">
              <img
                src={RatingService}
                className="w-[60px] m-auto"
                alt="Ambiance emoji"
              />
              {/* <i className="fas fa-bell-concierge text-4xl" /> */}
              Service <br />
              {Math.round(restaurant?.avgService! * 10) / 10}
            </div>
            <div className="flex flex-col w-full text-center">
              <img
                src={RatingAmbiance}
                className="w-[60px] m-auto"
                alt="Ambiance emoji"
              />
              {/* <i className="fa-solid fa-face-smile-beam text-4xl"></i> */}
              Ambiance <br />
              {Math.round(restaurant?.avgAmbiance! * 10) / 10}
            </div>
            <div className="flex flex-col w-full text-center">
              <img
                src={RatingQuality}
                className="w-[60px] m-auto"
                alt="Ambiance emoji"
              />
              {/* <i className="fa-solid fa-utensils text-4xl"></i> */}
              Qualité <br />
              {Math.round(restaurant?.avgQuality! * 10) / 10}
            </div>
          </div>
          <br />
          <div>
            Catégories :
            {restaurant.categories.map((c) => (
              <span key={c} className="font-bold ml-2">
                {c}
              </span>
            ))}
          </div>
          {restaurant.illustrations !== undefined &&
            restaurant.illustrations.length !== 0 && (
              <div>
                <div className="flex" style={{overflowX: "auto", height: "225px"}}>
                  {restaurant.illustrations.map((illustration) => {
                    return (
                      <SmartImage
                        key={illustration}
                        className="mx-2"
                        src={
                          axios.defaults.baseURL +
                          "Illustration/" +
                          illustration
                        }
                        alt="Restaurant"
                      />
                    );
                  })}
                </div>
              </div>
            )}

          <span className="font-bold">Commentaires : </span>
          <br />
          {restaurant?.descriptions.$values.map((d) => (
            <div className="mb-8" key={d.description}>
              {d.description}
              <br />
              <span className="italic">
                {DateTime.fromISO(d.publishAt).toLocaleString()} par{" "}
                {d.authorName}
              </span>
            </div>
          ))}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button
          className={redButton}
          onClick={() => {
            navigate("/add_restaurant?restaurantId=" + restaurant.id);
          }}
        >
          Recommander
        </button>
        <button onClick={() => onClose()} className={greenButton}>
          Fermer
        </button>
      </Modal.Footer>
    </Modal>
  );
}
