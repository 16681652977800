export type FullPageCardProps = {
    children?: JSX.Element | JSX.Element[];
}

export function FullPageCard({children}: FullPageCardProps) {
    return (
        <div className={"place-content-center card md:w-2/3 lg:w-2/5 m-auto overflow-auto min-h-[100%]"}>
            {children}
        </div>
    )
}