import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GoogleLoginButton } from "ts-react-google-login-component";
import AuthService from "../services/AuthService";
import { greenButton, neutralInput } from "../services/TailwindClassService";


export function GoogleLoginButtonComponent() {
  let navigate = useNavigate();

  const [name, setName] = useState("");

  const [acceptedCGU, setAcceptedCGU] = useState(false);
  const [addInNewsLetter, setAddInNewsLetter] = useState(false);
  const [showUserNameRequest, setShowUserNameRequest] = useState(false);
  const [googleIdToken, setGoogleIdToken] = useState<string | undefined>(
    undefined
  );
  const [errors, setErrors] = useState<string[]>([]);

  const onGoogleAuthSuccess = async (e: any) => {
    

    let googleLoginResponse = await AuthService.googleLogin((Object.values(e)[1] as any).id_token);
    setGoogleIdToken((Object.values(e)[1] as any).id_token);

    
    

    if(googleLoginResponse.status === 400 && googleLoginResponse.data.StringCode === "SHOULD_SIGNUP_FIRST") {
      setShowUserNameRequest(true);
    }
    // if(googleLoginResponse.)
  };

  const onUserNameCompleted = async () => {
    const response = await AuthService.googleLogin(
      googleIdToken!,
      acceptedCGU,
      name,
      addInNewsLetter
    );
    if(response.status !== 200) {
      setErrors([response.data.Message]);
    } else {
      setShowUserNameRequest(false);
    }
  };

  return (
    <>
      <Modal
        show={showUserNameRequest}
        onHide={() => setShowUserNameRequest(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Veuillez entrer un nom d'utilisateur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={"flex flex-col items-center"}>
            {errors.map((error) => (
              <div className={"text-red-500 text-xs"}>{error}</div>
            ))}
          </div>
          <label htmlFor="username-input">Nom d'utilisateur</label>
          <input
            id="username-input"
            className={neutralInput}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="flex items-center justify-between">
            <input
              value={acceptedCGU ? 1 : undefined}
              type="checkbox"
              aria-label=""
              id="accept-cgu-modal"
              title="J'accepte les Conditions Générales d'Utilisation"
              onChange={(e) => {
                setAcceptedCGU(e.currentTarget.checked);
              }}
            />
            <label htmlFor="accept-cgu-modal">
              J'accepte les{" "}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" onClick={() => navigate("/CGU")}>
                {" "}
                Conditions Générales d'Utilisation
              </a>
            </label>
          </div>
          <div className="flex items-center justify-between">
            <input
              value={addInNewsLetter ? 1 : undefined}
              type="checkbox"
              aria-label=""
              id="accept-newsletter-modal"
              title="Etre tenu(e) au courant des nouveautés"
              onChange={(e) => {
                setAddInNewsLetter(e.currentTarget.checked);
              }}
            />
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <label htmlFor="accept-newsletter-modal">
              Etre tenu(e) au courant des nouveautés
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={greenButton}
            disabled={!acceptedCGU}
            onClick={onUserNameCompleted}
          >
            Confirmer
          </button>
        </Modal.Footer>
      </Modal>
      <GoogleLoginButton
        clientConfig={{
          client_id:
            "398261000153-olbfsci6k4q1ko5vhdcdde4n7to1ne1t.apps.googleusercontent.com",
        }}
        responseHandler={onGoogleAuthSuccess}
      >
        <div className="google-logo" />
        {/* Style of google */}
        <button className="bold text-[1rem] border-2 rounded p-2 border-teal-700 hover:bg-teal-700 hover:text-white transition cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none">
          <i className="fa-brands fa-google mr-2"></i>Continuer avec Google
        </button>
      </GoogleLoginButton>
    </>
  );
}
