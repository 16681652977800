import { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { AggregatedRestaurant } from "../services/RestaurantService";
import { greenButton } from "../services/TailwindClassService";
import { RestaurantListItem } from "./RestaurantListItem";
import { useTranslation } from "react-i18next";
import AddressService from "../services/AddressService";

export function RestaurantsList({
  restaurants,
  baseLatLong, 
}: {
  restaurants: AggregatedRestaurant[];
  baseLatLong?: [number, number];
}) {
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState<"distance" | "price" | "rating">(
    "distance"
  );
  const distance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist * 1.609344;
  };

  const options: { value: "distance" | "price" | "rating"; label: string }[] = [
    { value: "distance", label: "Distance" },
    { value: "price", label: t("Prix") },
    { value: "rating", label: "Note" },
  ];
  const popover = (
    <Popover id="popover-basic" className="w-[100%]">
      <Popover.Body>
        {options.map((kv) => (
          <div key={kv.value} className="flex justify-between text-lg w-[100%]">
            <div className="flex items-center cursor-pointer">
              <input
                title="Trier par"
                type="radio"
                name="sortBy"
                value={kv.value}
                checked={sortBy === kv.value}
                onChange={() => setSortBy(kv.value)}
              />
              <label className="ml-2 cursor-pointer" onClick={()=> setSortBy(kv.value)}>{kv.label}</label>
            </div>
            
          </div>
        ))}
      </Popover.Body>
    </Popover>
  );
  return (
    <div className={"bg-gray-100 pt-2 px-2 flex flex-col flex-1 h-[80%]"}>
      <div className={"flex justify-between mt-4"}>
        <h4>Restaurants</h4>
        <div>
          <OverlayTrigger trigger="click" placement="left" overlay={popover}>
            <button className={greenButton}>Trier</button>
          </OverlayTrigger>
        </div>
      </div>
      {/* iflist is to long then scroll */}
      <div className="flex-1 h-[100%]" style={{ overflowX: "auto" }}>
        {restaurants.length === 0 && (
          <div className={"flex justify-center h-8 "}>
            <div className={"text-center"}>
              {t("Aucun restaurant trouvé")}
            </div>
          </div>
        )}
        {restaurants
          .sort((a, b) => {
            
            

            if (sortBy === "distance") {
              if(AddressService.latLongUsed) {

                let distanceA = distance(
                  baseLatLong![0],
                  baseLatLong![1],
                  a.location[0],
                  a.location[1]
                );
                let distanceB = distance(
                  baseLatLong![0],
                  baseLatLong![1],
                  b.location[0],
                  b.location[1]
                );
                return distanceA - distanceB;
                
              }
            } else if (sortBy === "price") {
              return a.avgPrice - b.avgPrice;
            } else if (sortBy === "rating") {
              return b.average - a.average;
            }
            return 1;
          })
          .map((restaurant) => {
            return (
              <RestaurantListItem
                restaurant={restaurant}
                baseLatLong={baseLatLong}
              />
            );
          })}
      </div>
    </div>
  );
}
