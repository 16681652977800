import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BackToHomePage } from "../components/BackToHomePage";
import { FullPageCard } from "../components/FullPageCard";
import { RestaurantsList } from "../components/RestaurantsList";
import AddressService from "../services/AddressService";
import FriendService, { FriendProfile } from "../services/FriendService";
import LoaderService from "../services/LoaderService";
interface UserProfileInterface {
    userId?: string;
    hideBackButton?: boolean;
}
export function UserProfile({userId, hideBackButton}: UserProfileInterface) {
    const [user, setUser] = useState<FriendProfile | undefined>(undefined);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        LoaderService.onLoad!(true);
        if (userId || searchParams.has("id")) {
            FriendService.getFriendProfile(userId ? userId! : searchParams.get("id")! ).then((res) => {
                setUser(res);
                LoaderService.onLoad!(false);
                }
            );
        } else {
            navigate("/friends");
        }
    }
    , [navigate, searchParams, userId]);
    return (
        <div className="flex-1 h-[100%]">
        <FullPageCard>
            <>
            {!hideBackButton && <BackToHomePage />}
            </>
            <h1>{user?.name}</h1>
            <h3>{user?.restaurantsPublished ? user?.restaurantsPublished?.$values.length : 0} recommandations</h3>
            <RestaurantsList restaurants={user?.restaurantsPublished ? user?.restaurantsPublished.$values : []} baseLatLong={AddressService.latLongUsed} />
        </FullPageCard>
        </div>
    )
}