import Logo from "../assets/BackfooderLogoV2.png";

import { Adsense } from "@ctrl/react-adsense";
import { greenButton } from "../services/TailwindClassService";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AuthService from "../services/AuthService";
import DinnerImgSVG from "../assets/dinner.svg";
import { Login } from "./Login";

export function Home() {
  let navigate = useNavigate();
  // when mounted
  useEffect(() => {
    if (AuthService.getUserAuth() !== undefined) {
      navigate("/discover");
    }
  }, [navigate]);

  return (
    <div>
      <img alt="Backfooder Logo" className={"m-auto mb-4 h-20"} src={Logo} />
      <div className="overflow-x-hidden overflow-y-auto h-[77vh]">
        <h2 className={"text-2xl md:text-4xl font-semibold"}>
          Ta carte aux trésors gastronomique.
        </h2>
        <h3 className="text-xl font-normal">
          Partage et trouve des adresses de confiance.
        </h3>
        {/* Centered div */}

        <div>
          <p className="lg:text-right">
            {/* Who are we */}
            <div
              style={{}}
              className="md:text-center md:w-4/5 md:m-auto flex flex-wrap"
            >
              <img
                alt="Wine"
                src={DinnerImgSVG}
                className={"lg:w-2/5 md:w-[40%] lg:mt-[-100px] -z-50"}
              />
              <div className="m-auto w-5/5 md:w-[60%] ">
                <Login />
              </div>
            </div>
            <br />
            <div className="flex flex-wrap md:w-4/5 justify-between m-auto mx-4 lg:ml-8 lg:mr-0">
              <h2 className="mt-4 lg:w-2/5 text-center font-bold text-2xl">Qui sommes-nous ?</h2>
              <p className="lg:w-2/5 m-auto text-lg text-left">
                Hello <span className="text-teal-700">Fooder</span> ! <br/><br/>
                C'est simple, ici, on est passionés de bouffe et de voyages. <br/><br/>
                Mais ce qui rend Backfooder vraiment spécial, c'est toi et ta bande d'amis,
                partageant vos adresses secrètes comme si vous étiez des pirates
                du goût. <br/><br/> Oui, la plateforme tourne avant tout autour du partage
                communautaire. <br/><br/> Des petites pépites aux grandes trouvailles,
                c'est l'endroit où tu peux échanger tes coups de cœur culinaires
                avec tes amis. Et pour pimenter le tout, nous, l'équipe de
                Backfooder, sommes là pour ajouter nos propres recommandations
                triées sur le volet. 
                <br/><br/> Rejoins-nous et transforme chaque repas en
                une nouvelle aventure !
              </p>
            </div>
          </p>
        </div>
      </div>
      <Link to={"/CGU"} className="mr-4">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#">Politique de Confidentialité & CGU</a>
      </Link>
      |
      <Link to={"/Contact"} className="ml-4">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#">Contact</a>
      </Link>
    </div>
  );
}
