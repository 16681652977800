import axios from "axios";

export type ContactFormModel = {
    userId: string;
    subject: string;
    message: string;
    email: string;
}

export default new class ContactService {
    private endpoint = "Contact";
    public async postMessage(model: ContactFormModel) {
        const response = await axios.post(`${this.endpoint}`, model);
        return response;
    }
}()