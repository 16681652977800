import React, { useState } from 'react';

const SmartImage: React.FC<{ src: string, alt: string, className: string }> = ({ src, alt, className }) => {
  const [imageLoaded, setImageLoaded] = useState(true);

  const handleError = () => {
    setImageLoaded(false);
  };

  return (
    <>
      {imageLoaded && <img src={src} alt={alt} onError={handleError} className={className} />}
    </>
  );
};

export default SmartImage;