import { useEffect, useState } from "react";
import "./App.css";
import { BackFoodRoutes } from "./components/BackFoodRoutes";
import { QueryClient, QueryClientProvider } from "react-query";
import LoaderService from "./services/LoaderService";
import { Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";
import AuthService from "./services/AuthService";
import axios from "axios";
import Radar from "radar-sdk-js"
import i18n from "i18next";
import {  initReactI18next } from "react-i18next";
import i18nConfig from "./translation/translate.json"
// import LanguageDetector from 'i18next-browser-languagedetector';


const queryClient = new QueryClient();

axios.defaults.validateStatus = function (status) {
  return status >= 200 && status < 500;
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(i18nConfig)
  

  // i18n.use(LanguageDetector).init();

function App() {
  const [loading, setLoading] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const navigate = useNavigate();
  const size = useWindowSize();

  
  AuthService.onAuthChange = (before, after) => {
    
    if (!after) {
      navigate("/login");
    } else {
      const from = window.location.search.split("from=")[1];
      if (from) {
        navigate(from);
      } else {
        navigate("/discover");
      }
      Radar.trackOnce();
    }

  };

  useEffect(() => {
    LoaderService.onLoad = (yes) => {
      setLoading(yes);
    };
    AuthService.revalidateLogin();
  }, []);

  return (
    <div
      className="App h-[95vh]"
      style={{height: size.height}}
    >
      {/* You are now signed up modal */}
      <Modal show={showSignupModal} onHide={() => setShowSignupModal(false)}>
        <Modal.Header closeButton>
          <h1>Vous êtes désormais inscrit !</h1>
        </Modal.Header>
      </Modal>
      {loading && (
        <div className="w-full mb-4" style={{height: "100vh"}}>
          <Spinner animation={"border"} className="text-teal-700 text-4xl"/>
          <h1>Chargement</h1>
        </div>
      )}

      <QueryClientProvider client={queryClient}>
        <BackFoodRoutes />
      </QueryClientProvider>

    </div>
  );
}

export default App;


// Define general type for useWindowSize hook, which includes width and height
interface Size {
  width: number | undefined;
  height: number | undefined;
}
// Usage

// Hook
function useWindowSize(): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}


