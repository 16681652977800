import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { LocateRestaurant } from "../components/AddRestaurantSteps/LocateRestaurant";
import { PropositionAddresse } from "../components/AddRestaurantSteps/PropositionAddresse";
import { RateRestaurant } from "../components/AddRestaurantSteps/RateRestaurant";
import { FullPageCard } from "../components/FullPageCard";
import { defaultLocationLatLon } from "../services/AddressService";
import LoaderService from "../services/LoaderService";
import RestaurantService, { PublishRestaurantModel } from "../services/RestaurantService";
import { useTranslation } from "react-i18next";
import { RestaurantIllustration } from "../components/AddRestaurantSteps/RestaurantIllustration";
import IllustrationService from "../services/IllustrationService";
import { debug } from "console";


export function AddRestaurant() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<
    "locate" | "help-locate" | "rating" | "illustration"
  >("locate");

  const loc = useLocation();
  const { t } = useTranslation();
  
  const [restaurant, setRestaurant] = useState<PublishRestaurantModel>({
    name: "",
    location: [0, 0],
    description: "",
    categories: [],
    illustrations: [],
    address: "",
    city: "",
    ratings: {
      price: 0,
      quality: 6,
      service: 6,
      ambiance: 6,
    },
  });

  const uploadFiles = async (files: File[]) => {
   const illustrationIds = await IllustrationService.UploadFiles(files);
   
    setRestaurant({
      ...restaurant,
      illustrations: illustrationIds,
    });
    onSubmit({...restaurant, illustrations: illustrationIds});
  }

  useEffect(() => {
    const searchLocationUrl = new URLSearchParams(loc.search);

    const restaurantId = searchLocationUrl.get("restaurantId");
    if(restaurantId !== null && restaurant.restaurantId == null) {
      setRestaurant({...restaurant, restaurantId})
      setCurrentStep("rating");
    }
  }, [restaurant, loc])

 

  const onRestaurantRated = (restaurantSubmitted: PublishRestaurantModel) => {
    
    setRestaurant(restaurantSubmitted);
    setCurrentStep("illustration");
  }

  const onSubmit = (restaurantSubmitted: PublishRestaurantModel) => {
    LoaderService.onLoad!(true);
    setRestaurant(restaurant);
    RestaurantService.publishRestaurant(restaurantSubmitted).then(() => {
      LoaderService.onLoad!(false);
      navigate("/discover");
    });
  }

  return (
    <FullPageCard>
      <h1 className="text-2xl">{t("Ajouter un")} <span className="text-teal-700">restaurant</span></h1> 
      <div>
        {(currentStep === "locate" && (
          <LocateRestaurant
            {...restaurant}
            next={(r) => {
              setRestaurant(r);

              if (r.location === defaultLocationLatLon || r.location === [0,0]) {
                setCurrentStep("help-locate");
              } else {
                setCurrentStep("rating");
              }
            }}
          />
        )) ||(( currentStep === "help-locate" && (
          <PropositionAddresse address={restaurant.address} next={(address) => {
            setRestaurant({
              ...restaurant,
              address: address.display_name,
              location: [address.lat, address.lon],
            });
            setCurrentStep("rating");

          }}
          previous={() => {
            setCurrentStep("locate");
          }}
          
          />))
          ) || (currentStep === "rating" && (
            <RateRestaurant {...restaurant} onSubmit={onRestaurantRated}/>
          )) || (currentStep === "illustration" && (
            <RestaurantIllustration restaurant={restaurant} onSubmit={uploadFiles}/>))}
      </div>
    </FullPageCard>
  );
  
}
