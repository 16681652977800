import { Navigate, useNavigate } from "react-router-dom";
import { BurgerMenu } from "./BurgerMenu";
import { useTranslation } from "react-i18next";
import {GiCook} from "react-icons/gi"

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  outlet: JSX.Element;
};

export default function ProtectedRoute({
  isAuthenticated,
  authenticationPath,
  outlet,
}: ProtectedRouteProps) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  // Get the current url
  const currentUrl = window.location.pathname;
  // Get the query params
  const queryParams = window.location.search;

  if (isAuthenticated) {
    return (
      <div className="app-content">
        {/* <LogoutButton className={"absolute right-2  md:right-64"} /> */}
        <BurgerMenu className={"absolute right-2 sm:right-32 lg:right-64 z-[1]"} />

        {outlet}
        {/* fixzed bottom navigation bar white and green with tailwind*/}
        <div className="w-full">
          <section
            id="bottom-navigation"
            className="block fixed inset-x-0 bottom-0 z-50 shadow h-13 bg-teal-700"
            // style={{backgroundColor: "#04151F"}}
          >
            <div id="tabs" className="flex justify-between">
              <div
                onClick={() => {
                  navigate("/discover");
                }}
                className="w-2/6 focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-1 pb-1 cursor-pointer transition"
              >
                <i className="fas fa-home fa-lg text-white"></i>
                <div className="text-white mt-[-3px]">{t("Accueil")}</div>
              </div>
              {/* <div
              onClick={() => {
                navigate("/groups");
              }}
              className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-1 pb-1 cursor-pointer transition">
                <i className="fas fa-users fa-lg"></i>
                <div>Groupes</div>
              </div> */}
              <div
                className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-1 pb-1 cursor-pointer transition"
                id="add-restaurant-navigation"
                onClick={() => {
                  navigate("/add_restaurant");
                }}
              >
                <i className="fas fa-plus fa-lg text-white"></i>
                <div className="text-white mt-[-3px] ">
                  {t("Ajouter un restaurant")}
                </div>
              </div>
              <div
                id="friends-navigation"
                onClick={() => {
                  navigate("/friends");
                }}
                className="w-2/6 focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-1 pb-1 cursor-pointer transition"
              >
                <GiCook className="text-[1.25em] text-white hover:text-white align-center m-auto leading-[0.05em] mb-1"/>
                {/* <i className="fas fa-user fa-lg text-white"></i> */}
                <div className="text-white mt-[-3px]">{t("Profil")}</div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  } else {
    return (
      <Navigate
        to={{
          pathname:
            authenticationPath + "?from=" + encodeURI(currentUrl + queryParams),
        }}
      />
    );
  }
}
