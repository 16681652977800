import L from 'leaflet';
import markerIcon from "../../assets/markers/restaurantAndBeer.svg";
const restaurantAndBarMarker = new L.Icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon,
    popupAnchor: [0, 0],
    tooltipAnchor: [0, 0],
    iconSize: [40, 40],
    iconAnchor: [25, 40],
});

export { restaurantAndBarMarker };