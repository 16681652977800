import { useNavigate } from "react-router-dom";
import { greenButton } from "../services/TailwindClassService";

export function BackToHomePage() {
    const navigate = useNavigate();
    return (
        <div className={"absolute left-0"}>
        <button
          className={"" + greenButton + " ml-4 w-8 p-0"}
          id="logout-button"
          title={`Retour à l'accueil`}
          onClick={() => {
            navigate(-1);
          }}
        >
          <i className="fa fa-arrow-left ml-[-7px]" />
        </button>
      </div>
    )
}