import { DateTime } from "luxon";

export interface SimpleAddress {
  display_name: string;
  address: string;
  lat: number;
  lon: number;
}

export interface AddressSearchResult {
  display_name: string;
  address: string;
  lat: number;
  lon: number;
  place_id: string;
  types: string[];
}
export const defaultLocationLatLon: [number, number] = [48.856614, 2.3522219];
export default new (class AddressService {
  private queue: {
    [inputId: string]: {
      cb: (result: AddressSearchResult[]) => void;
      resolved: boolean;
      requestedAt: DateTime;
      address: string;
      method: (address: string) => Promise<AddressSearchResult[]>;
    }[];
  };

  private latLong: [number, number] = defaultLocationLatLon;

  public set setLatLong(latLong: [number, number]) {
    this.latLong = latLong;
  }

  public get latLongUsed(): [number, number] {
    return this.latLong;
  }

  constructor() {
    this.queue = {};
    this.findAddress = this.findAddress.bind(this);
    this.findPOI = this.findPOI.bind(this);
  }

  async findAddressByInput(
    inputId: string,
    address: string,
    cb: (result: AddressSearchResult[]) => void,
    geo?: boolean
  ) {
    if (!this.queue[inputId]) {
      this.queue[inputId] = [];
    }
    const queue = this.queue[inputId];
    queue.push({
      cb,
      resolved: false,
      requestedAt: DateTime.now(),
      address,
      method: (a) => {
        return this.findAddress(a, geo);
      },
    });
    setTimeout(async () => {
      await this.iterateQueue();
    }, 1005);
  }

  async findPOIByInput(
    inputId: string,
    address: string,
    city: string,
    cb: (result: AddressSearchResult[]) => void,
    closeTo?: { latLon: [number, number]; meters: number }
  ) {
    if (!this.queue[inputId]) {
      this.queue[inputId] = [];
    }
    const queue = this.queue[inputId];
    queue.push({
      cb,
      resolved: false,
      requestedAt: DateTime.now(),
      address,
      method: (a) => {
        return this.findPOI(a, city);
      },
    });
    setTimeout(async () => {
      await this.iterateQueue();
    }, 1005);
  }

  async iterateQueue() {
    for (const inputId in this.queue) {
      if (this.queue.hasOwnProperty(inputId)) {
        const queue = this.queue[inputId];
        const last = queue[queue.length - 1];

        // resolve last in queue if it is not resolved yet
        if (!last.resolved) {
          let now = DateTime.now();

          if (now.diff(last.requestedAt).milliseconds >= 500) {
            const response = await last.method(last.address);
            last.cb(response);
            last.resolved = true;
          }
        }
      }
    }
  }

  private async findPOI(
    address: string,
    city: string
  ): Promise<AddressSearchResult[]> {
    let url = `https://nominatim.openstreetmap.org/search.php?q=${encodeURI(
      address
    )}&city=${city}&format=jsonv2`;

    const response = await fetch(url);
    const result = await response.json();

    return result.map((r: any) => {
      return {
        display_name: r.display_name.split(",")[0],
        address: r.display_name.split(",").slice(1).join(","),
        lat: r.lat,
        lon: r.lon,
        place_id: 1,
        types: [],
      };
    });
  }

  private async findAddress(
    address: string,
    geo?: boolean
  ): Promise<AddressSearchResult[]> {
    // Use tomtom api to find address
    let url = `https://api.tomtom.com/search/2/search/${encodeURIComponent(
      address
    )}.json?key=Bz94EfSzJYK2Is3AKjz8BdRwxjFvAjxz&limit=20&lat=${
      this.latLong[0]
    }&lon=${this.latLong[1]}`;
    if (geo) {
      url += "&idxSet=Geo";
    }
    const response = await fetch(url);
    const result = await response.json();
    // return results mapped to AddressSearchResult

    return result.results.map((r: any) => {
      return {
        display_name: r.address.freeformAddress,
        country: r.address.country,
        lat: r.position.lat,
        lon: r.position.lon,
        place_id: r.address.id,
        types: r.address.types,
      };
    });
  }
})();
