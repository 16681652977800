import { useEffect, useState } from "react";
import { FriendListItem } from "../components/FriendListItem";
import { basicInput, greenButton } from "../services/TailwindClassService";
import LoaderService from "../services/LoaderService";
import FriendService, { Friend } from "../services/FriendService";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Modal } from "react-bootstrap";
import PartyIcon from "../assets/party.svg";
import AuthService from "../services/AuthService";
import { UserProfile } from "./UserProfile";
export function FriendsPage() {
  const [chosenView, setChosenView] = useState<"profile" | "friends" | "add">("profile");
  const [added, setAdded] = useState<string[]>([]);
  const [friends, setFriends] = useState<Friend[]>([]);
  const [pendingFriends, setPendingFriends] = useState<Friend[]>([]);
  const [foundFriend, setFoundFriend] = useState<Friend | undefined>(undefined);
  const [cheatcode, setCheatcode] = useState<string>("");
  const [searchParams] = useSearchParams();
  const [autoAdded, setAutoAdded] = useState<boolean>(false);
  const navigate = useNavigate();

  const reloadPageData = async () => {
    LoaderService.onLoad!(true);
    if (searchParams.has("cc")) {
      await FriendService.addWithCheatCode(searchParams.get("cc")!);
      setAutoAdded(true);
    }
    let friends = await FriendService.getFriends();
    let pendingFriends = await FriendService.getPendingFriendRequests();
    setFriends(friends);
    setPendingFriends(pendingFriends);
    const cheatcode = await FriendService.getCheatCode();
    setCheatcode(cheatcode);
    LoaderService.onLoad!(false);
  };

  useEffect(() => {
    reloadPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFindUserFormChange = (e: string) => {
    setFoundFriend(undefined);
    if (e.length >= 2) {
      FriendService.searchFriend(e).then((res) => {
        if (res.id !== AuthService.getUserAuth()?.id) {
          setFoundFriend(res);
        }
      });
    }
  };

  const onFriendClick = (id: string) => {
    navigate(`/profile?id=${id}`);
  }

  const nativeShare = (e: any) => {
    e.preventDefault();
    navigator.share({
      title: "Backfooder",
      text: "Bonjour ! Viens découvrir mes bonnes adresses sur Backfooder !",
      url: "https://backfooder.fr/friends?cc=" + cheatcode,
    });
  };

  const alreadyAdded = (id: string) => {
    return added.includes(id) || friends.some((f) => f.id === id);
  };

  const onAddFriend = () => {
    FriendService.addFriend(foundFriend!.id).then(() => {
      setAdded([...added, foundFriend!.id]);
    });
  };

  const renderFriends = () => {
    return (
      <>
            {pendingFriends.length > 0
              ? pendingFriends.map((g) => {
                  return (
                    <FriendListItem
                    
                      onAction={() => {
                        reloadPageData();
                      }}
                      key={g.id}
                      friend={{ ...g, type: "friendRequest" }}
                    />
                  );
                })
              : undefined}
            {friends.length > 0 ? (
              friends.map((g) => {
                return (
                  <>
                  <FriendListItem
                    onClick={() => {onFriendClick(g.id)}}
                    onAction={() => {
                      reloadPageData();
                    }}
                    key={g.id}
                    friend={{ ...g, type: "friend" }}
                  />
                  </>
                );
              })
            ) : (
              <div className="mt-16 h-[20vh] overflow-hidden">
                <div className="flex justify-center">
                  Aucun ami pour le moment
                </div>
              </div>
            )}
          </>
    )
  }

  const renderFriendsSearch = () => {
    return (
      <>
            <div className={"flex flex-wrap mb-4 mt-4 justify-around"}>
              <input
                className={
                  basicInput + " mx-4"
                }
                placeholder="Nom d'utilisateur de l'ami(e) à ajouter"
                onChange={(e) => {
                  onFindUserFormChange(e.target.value);
                }}
              />
              {/* show user if request ended */}
            </div>

            {foundFriend ? (
              <div>
                <div
                  className={"flex flex-wrap justify-around bg-gray-200 pt-4 mx-4 rounded-lg"}
                >
                  <div className={"w-1/2"}>
                    <h2>{foundFriend.name}</h2>
                  </div>
                  <div className={"w-1/2"}>
                    <button
                      className={greenButton + "h-10 align-middle mb-8"}
                      onClick={onAddFriend}
                      disabled={alreadyAdded(foundFriend.id)}
                    >
                      {alreadyAdded(foundFriend.id) ? "Déjà ajouté" : "Ajouter"}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {/* Icone de loupe */}
                <i className="fas fa-search fa-2x" />
                <h2>Tapez le nom d'utilisateur</h2>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="sm:hidden text-lg"
                  href="#"
                  onClick={(e) => {
                    nativeShare(e);
                  }}
                >
                  Ou partagez ce lien
                </a>
              </div>
            )}
          </>
    )}

    const renderProfile = () => {
      return (
        <div className="flex-1 h-[100%]">

        <UserProfile hideBackButton userId={AuthService.getUserAuth()?.id} />
        </div>

      )
    }

  return (
    <div className="flex flex-col h-[100%]">
      <Modal show={autoAdded} onHide={() => navigate("/discover")}>
        <Modal.Header closeButton>
          <Modal.Title>Bravo !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={PartyIcon} alt="fête" className="w-2/5 m-auto pb-8" />
            <p>Vous êtes désormais connecté à votre ami(e) !</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              navigate("/discover");
            }}
            className={greenButton}
          >
            Fermer
          </button>
        </Modal.Footer>
      </Modal>
      <h1 className="top">
        Mon <span className="text-teal-700"> profil </span>
      </h1>
      <div className="w-full">

        <div
          id="bottom-navigation"
          className="block inset-x-0 bottom-0 z-10 bg-white shadow h-16 mt-4"
        >
          <div id="tabs" className="flex justify-between">
          <div
              onClick={() => {
                setChosenView("profile");
              }}
              id="friends"
              className={
                "w-full focus:text-teal-900 hover:text-teal-700 justify-center inline-block text-center pt-2 pb-1 cursor-pointer transition" +
                (chosenView === "profile" ? " text-teal-700" : "")
              }
            >
              <i className="fas fa-user fa-xl"></i>
              <div>Profil</div>
            </div>
            <div
              onClick={() => {
                setChosenView("friends");
              }}
              id="friends"
              className={
                "w-full focus:text-teal-900 hover:text-teal-700 justify-center inline-block text-center pt-2 pb-1 cursor-pointer transition" +
                (chosenView === "friends" ? " text-teal-700" : "")
              }
            >
              <i className="fas fa-people-group fa-xl"></i>
              <div>Amis</div>
            </div>
            <div
              onClick={() => {
                setChosenView("add");
              }}
              className={
                "w-full focus:text-teal-900 hover:text-teal-700 justify-center inline-block text-center pt-2 pb-1 cursor-pointer transition" +
                (chosenView === "add" ? " text-teal-700" : "")
              }
            >
              <i className="fas fa-plus fa-xl"></i>
              <div>Ajouter un ami</div>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-auto flex-1">
        {(chosenView === "friends" && renderFriends()) || (chosenView === "add" && renderFriendsSearch()) || (chosenView === "profile" && renderProfile())}
          
        
      </div>
    </div>
  );
}
