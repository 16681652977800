import { useState } from "react";
import { AggregatedRestaurant } from "../services/RestaurantService";
import { RestaurantDetailsModal } from "./RestaurantDetailsModal";

export function RestaurantListItem({
  restaurant,
  baseLatLong,
}: {
  restaurant: AggregatedRestaurant;
  baseLatLong?: [number, number];
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const distance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist * 1.609344;
  };
  return (
    <div
      className={
        "border-b-2 border-teal-700 m-h-20 hover:bg-gray-200 cursor-pointer p-2"
      }
    >
      <RestaurantDetailsModal restaurant={restaurant} onClose={() => setModalOpen(false)} show={modalOpen}/>
      <div className="flex justify-between" onClick={() => setModalOpen(true)}>
        <p className="ml-4 text-left">
          <strong>{restaurant.name}</strong> <br />
          {restaurant.address} <br />
          {baseLatLong
            ? distance(
                baseLatLong[0],
                baseLatLong[1],
                restaurant.location[0],
                restaurant.location[1]
              ).toFixed(2) + " km"
            : ""}
        </p>
        <p className="w-1/5"    >
          <strong >
            {Math.round(restaurant.average * 10) / 10} / 10
          </strong>
        </p>
      </div>
    </div>
  );
}
