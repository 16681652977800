import {useNavigate} from "react-router-dom";
import {IoLogOut } from "react-icons/io5";
import { cancelButtonRounded } from "../services/TailwindClassService";
import AuthService from "../services/AuthService";
import { useTranslation } from "react-i18next";


export function LogoutButton(props: {className?: string}) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const logoutFromApp = () => {
        AuthService.logout()
        navigate("/")
    }

    return (
        <div className={"text-left"} style={{}}>
            {t("Déconnexion")}
        <button title={t("Déconnexion")}  onClick={logoutFromApp} className={cancelButtonRounded + " w-4 p-0 " + props.className + " ml-4"} id="logout-button">
            <IoLogOut style={{fontSize: "1.5rem", marginLeft: "-10px"}} className={"inline-block text-teal-700"}/>
        </button>
        </div>
    )
}