import { useState } from "react";
import { greenButton, neutralInput } from "../services/TailwindClassService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FullPageCard } from "../components/FullPageCard";
import AuthService from "../services/AuthService";
import { Modal } from "react-bootstrap";
import { GoogleLoginButtonComponent } from "../components/GoogleLogin";
import { useTranslation } from "react-i18next";

export function Register() {
  let navigate = useNavigate();
  const {t} = useTranslation();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [acceptedCGU, setAcceptedCGU] = useState(false);
  const [addInNewsLetter, setAddInNewsLetter] = useState(false);
  
  const [showSignedUpModal, setShowSignedUpModal] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [searchParams] = useSearchParams();

  const signup = () => {
    AuthService.register(
      {
        username: name,
        email,
        password,
        addInNewsLetterList: addInNewsLetter,
      },
      (response) => {
        if (response.status === 200) {
          setShowSignedUpModal(true);
        } else if (response.status === 400) {
          setErrors([response.data.Message]);
        }
      }
    );
  };

  const hideSignedUp = () => {
    setShowSignedUpModal(false);
    navigate("/login" + (searchParams.has("from") ? `?from=${searchParams.get("from")}` : ""));
  };

  return (
    <div>
      <Modal show={showSignedUpModal} onHide={hideSignedUp}>
        <Modal.Header closeButton>
          <Modal.Title>Vous êtes désormais inscrit !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Vous pouvez maintenant vous connecter avec vos identifiants.
        </Modal.Body>
        <Modal.Footer>
          <button onClick={hideSignedUp} className={greenButton}>
            Fermer
          </button>
        </Modal.Footer>
      </Modal>
      {/* Modal that asks for username */}
      <FullPageCard>

        <div className={"text-center"}>
          <h3 className={"text-xl"}>Inscription</h3>
          <p className={"text-xs"}>
            Complète le formulaire ci-dessous pour t'inscrire ou bien
            connecte-toi directement avec Google.
          </p>
        </div>
        <div className="flex items-center justify-around mt-6">
            <GoogleLoginButtonComponent/>
          </div>
        <div className={"flex flex-col items-center"}>
          {errors.map((error) => (
            <div className={"text-red-500 text-xs"}>
              <strong>{error}</strong>
            </div>
          ))}
        </div>
        <div className={"text-left ml-5 mr-5 mt-10"}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Pseudonyme
            </label>
            <input
              value={name}
              onChange={(e) => {
                setName(e.currentTarget.value);
              }}
              className={neutralInput}
              id="username"
              type="text"
              placeholder={t("Nom d'utilisateur")}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Adresse email
            </label>
            <input
              value={email}
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
              className={neutralInput}
              id="email"
              type="text"
              placeholder="example@example.org"
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Mot de passe
            </label>
            <input
              value={password}
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
              className={neutralInput}
              id="password"
              type="password"
              placeholder="******************"
            />
            <p className="text-red-500 text-xs italic">
              Merci de renseigner un mot de passe.
            </p>
          </div>
          <div className="mb-6">
            {/* Checkbox accept CGU */}
            <div className="flex items-center justify-between">
              <input
                value={acceptedCGU ? 1 : undefined}
                type="checkbox"
                aria-label=""
                id="accept-cgu"
                title="J'accepte les Conditions Générales d'Utilisation"
                onChange={(e) => {
                  setAcceptedCGU(e.currentTarget.checked);
                }}
              />
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <label htmlFor="accept-cgu">
                J'accepte les{" "}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" onClick={() => navigate("/CGU")}>
                  {" "}
                  Conditions Générales d'Utilisation
                </a>
              </label>
            </div>
            <div className="flex items-center justify-between">
              <input
                value={addInNewsLetter ? 1 : undefined}
                type="checkbox"
                aria-label=""
                id="accept-newsletter"
                title="Etre tenu(e) au courant des nouveautés"
                onChange={(e) => {
                  setAddInNewsLetter(e.currentTarget.checked);
                }}
              />
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <label htmlFor="accept-newsletter">
                Etre tenu(e) au courant des nouveautés
              </label>
            </div>
          </div>
          <div className={"flex flex-col items-center"}>
            {errors.map((error) => (
              <div className={"text-red-500 text-xs"}>
                <strong>{error}</strong>
              </div>
            ))}
          </div>
          <div className="flex items-center justify-between">
            <button
              className={greenButton}
              type="button"
              onClick={signup}
              disabled={!acceptedCGU}
            >
              Inscription
            </button>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="inline-block align-baseline font-bold text-sm text-teal-700 hover:text-teal-900 cursor-pointer"
              onClick={() => {
                navigate("/login" + (searchParams.has("from") ? `?from=${searchParams.get("from")}` : ""));
              }}
            >
              Déja inscrit ?
            </a>
          </div>
         
        </div>
      </FullPageCard>
      
    </div>
  );
}
