import axios from "axios";

export default new class IllustrationService {
    private endpoint: string = "/Illustration";

    public async UploadFiles(files: File[]): Promise<string[]> {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }
        const response = await axios.post(this.endpoint, formData);
        return response.data.$values;
    }
}()