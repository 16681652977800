import L from 'leaflet';
import markerIcon from "../../assets/markers/position.svg";
const restaurantMarkerIcon = new L.Icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon,
    popupAnchor: [0, 0],
    tooltipAnchor: [10, 0],
    iconSize: [50, 50],
    iconAnchor: [25, 40],

});

export { restaurantMarkerIcon };