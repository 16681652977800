import L from 'leaflet';
import markerIcon from "../../assets/markers/beer.svg";
const beerMarker = new L.Icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon,
    popupAnchor: [0, 0],
    tooltipAnchor: [0, 0],
    iconSize: [40, 40],
    iconAnchor: [25, 40],

});

export { beerMarker };