import { useState } from "react";
import { Modal } from "react-bootstrap";
import AuthService from "../services/AuthService";
import { greenButton, neutralInput } from "../services/TailwindClassService";

export function ForgotPasswordModal() {
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [key, setKey] = useState("");
  const [newPassword, setNewPassword] = useState("");

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" onClick={() => setShowModal(true)} className="text-xs">
        Mot de passe oublié ?
      </a>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Mot de passe oublié ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(emailSent && (
            <div >
              <p>Un email vous a été envoyé.</p>
              {/* Input for code */}
              <label htmlFor="code">Code de validation (reçu par email)</label>
              <input
                value={key}
                onChange={(e) => {
                  setKey(e.currentTarget.value);
                }}
                className={neutralInput}
                id="code"
                type="text"
                placeholder="Code"
              />
              {/* Input for new password */}
              <label htmlFor="newPassword">Nouveau mot de passe</label>
              <input
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.currentTarget.value);
                }}
                className={neutralInput}
                id="newPassword"
                type="password"
                placeholder="Nouveau mot de passe"
              />
              {/* Button to submit */}
              <button
                onClick={() => {
                  AuthService.resetPassword(newPassword, key)
                    .then(() => {
                      setShowModal(false);
                    })
                    .catch((err) => {
                      
                    });
                }}
                className={greenButton}
                type="button"
              >
                Valider
              </button>
            </div>
          )) || (
            <>
              <p>
                Entrez votre adresse email pour réinitialiser votre mot de
                passe.
              </p>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Adresse email
                </label>
                <input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.currentTarget.value);
                  }}
                  className={neutralInput}
                  id="email"
                  type="text"
                  placeholder="example@example.fr"
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!emailSent && (
            <button
              className={greenButton}
              type="button"
              onClick={() => {
                AuthService.forgotPassword(email).then(() => {
                  setEmailSent(true);
                });
              }}
            >
              Envoyer le code
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
